import { ApolloProvider } from "@shopify/react-graphql";
import Authentication from "@views/Authentication";
import Collection from "@views/Collection";
import CollectionDetails from "@views/CollectionDetails";
import Payout from "@views/Payout";
import { InMemoryCache } from "apollo-cache-inmemory";
import { ApolloClient } from "apollo-client";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { Context } from "./GlobalState";
import Layout from "./Layout";
import PayoutForm from "./views/PayoutForm";

const App = () => {
  const { state } = useContext(Context);

  const httpLink = createHttpLink({
    uri: `${window.location.protocol}//${process.env.REACT_APP_HOST_URL}/graphql`,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        Authorization: "Bearer " + state.accessToken,
      },
    };
  });
  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
  });

  return (
    <ApolloProvider client={client}>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route
            path="/authenticate/:accessToken"
            element={<Authentication />}
          />
          <Route index element={<Collection />} />
          <Route path="/:id" element={<CollectionDetails />} />
          <Route path="/payout" element={<Payout />} />
          <Route path="/payout-form" element={<PayoutForm />} />
        </Route>
      </Routes>
    </ApolloProvider>
  );
};

export default App;
