import React from "react";

const Input = (props) => {
  const errors = props.errors
    ? props.errors.filter((item) => item.key === props.name)
    : [];

  return (
    <div
      style={{
        margin: "10px 0 0",
        borderRadius: "3px",
        padding: "10px",
        boxSizing: "border-box",
        display: "flex",
        width: "100%",
        flexDirection: "column",
        ...(errors.length > 0
          ? {
              backgroundColor: "#fdf5f5",
              border: "1px solid #ce2525",
            }
          : {}),
      }}
    >
      <dt style={{ fontSize: "14px", fontWeight: "300", marginBottom: "10px" }}>
        {props.title}
      </dt>
      <input
        style={{
          boxSizing: "border-box",
          width: "100%",
          height: "42px",
          padding: "8px 12px",
          fontSize: "16px",
          fontWeight: 500,
          border: "2px solid #000",
          borderRadius: "8px",
          color: "#000",
          background: "#fff",
          display: props.display? props.display:"",
        }}
        value={props.value}
        onChange={props.onChange}
        name={props.name}
        onFocus={props.onFocus}
        type={props.type}
      />
      {errors.map((item, idx) => (
        <div
          key={idx}
          className="error"
          style={{ color: "#e77577", marginTop: "5px" }}
        >
          {item.value}
        </div>
      ))}
    </div>
  );
};

export default Input;
