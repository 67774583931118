import { useQuery } from "@shopify/react-graphql";
import gql from "graphql-tag";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Input from "../components/Input";
import { PayoutDataStatus, PayoutStatus } from "../services/const";
import { useHookPayout } from "../services/useHookPayout";
import { isDisablePayoutFeature } from "../utils/base64";

const listStep = [
  "①通知先メールアドレスに間違いがないかを確認し、払出実行ボタンを押して下さい。",
  "②払出実行ボタンを押すと振込先設定画面に遷移します(ブラウザの設定環境により、振込先設定画面に遷移しない場合があります)。",
  "③設定画面の案内に従って振込先を設定してください。",
  "④振込が完了すると、振込依頼内容と振込結果の通知メールが届きます。",
  "②の振込設定画面に遷移するのと同時にお客様指定のメールアドレス宛に、振込先設定画面のURLと、ご本人様確認用のID / パスワードが届きます。こちらのURLからも振込先の設定が可能です。",
];
const listFAQ = [
  "振込実行ボタンを押すと振込先設定画面に遷移します。設定画面にてお振込先を設定ください。",
  "また、ご記入いただいたメールアドレス宛てに通知メールが届きます。",
  "通知メールからも、振込先設定のお手続きが可能です。メール内のURLにアクセスしID・パスワードを入力し手続を完了させてください。",
  "通知先メールアドレスにて、迷惑メールに入らないようにwell-net.jpドメインのメールアドレスから受信できるよう設定ください。",
];

const PayoutForm = () => {
  const {
    payment,
    setPayment,
    response,
    handleChangeInput,
    handleFocusInput,
    handleSubmit,
    handleToggleLoading,
  } = useHookPayout();

  const GET_CUSTODY = gql`
    query graphql {
      totalAndPayableAmount {
        totalAmount
        payableAmount
      }
    }
  `;

  const {
    loading: getcustody_loading,
    error: getcustody_error,
    data: getcustody_data,
    refetch: getcustody_refetch,
  } = useQuery(GET_CUSTODY);

  const GET_CUSTOMER = gql`
    query graphql {
      shopifyProfile {
        shopifyCustomerId
        email
      }
    }
  `;

  const {
    loading: getcustomer_loading,
    error: getcustomer_error,
    data: getcustomer_data,
    refetch: getcustomer_refetch,
  } = useQuery(GET_CUSTOMER);

  const navigate = useNavigate();

  useEffect(() => {
    if (
      getcustody_data &&
      getcustody_data.totalAndPayableAmount.payableAmount < 3000
    ) {
      navigate("/payout");
    }
  }, [getcustody_data]);

  useEffect(() => {
    if (getcustomer_data) {
      setPayment((prevPayment) => ({
        ...prevPayment,
        data: {
          email: getcustomer_data.shopifyProfile.email,
        },
      }));
    }
  }, [getcustomer_loading]);
  
  useEffect(() => {
    const handlePopState = () => {
        navigate(1);
    };

    window.history.pushState(null, null, window.location.href);
    window.addEventListener("popstate", handlePopState);

    return () => {
        window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);
  
  if (getcustody_loading || getcustomer_loading) {
    return (
      <main className="collection content">
        <div className="inner oikos"></div>
      </main>
    );
  }

  const payableAmount = getcustody_data.totalAndPayableAmount.payableAmount;
  const fee = 300;

  return (
    <>
      <main className="payout">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          className="inner"
        >
          <div className="payout-w-title">
            <h1>内訳・払出申請</h1>
          </div>
          <div className="payout-w-amount">
            <div className="inner">
              <div className="information">
                <dt>払出金額</dt>
                <dt>
                  ¥
                  {Math.trunc(payableAmount).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </dt>
              </div>
              <div className="information">
                <dt>振込手数料</dt>
                <dt>
                  -¥
                  {fee.toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </dt>
              </div>
              <div style={{ borderBottom: "1px solid #000", width: "100%" }} />
              <div className="information-summary">
                <dt>お振込金額</dt>
                <dt>
                  ¥
                  {(Math.trunc(payableAmount) - fee).toLocaleString(undefined, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </dt>
              </div>
            </div>
          </div>
          <div className="payout-w-form">
            <Input
              title="通知用メールアドレス (振込に必要なID/パスワードを送付します。)"
              name="email"
              onChange={handleChangeInput}
              onFocus={handleFocusInput}
              value={payment.data.email}
              errors={payment.errors}
              type="text"
              disabled={isDisablePayoutFeature()}
            />
            <Input
              name="id"
              value={payment.data.id}
              type="text"
              display="none"
            />
          </div>
          <div
            id="payout-note"
            style={{ marginTop: "10px", padding: "10px", fontWeight: 300 }}
          >
            <h2 style={{ fontWeight: "bold", marginBottom: "5px" }}>
              振り込み完了までの手続き
            </h2>
            <ul className="steps">
              {listStep.map((item, key) => (
                <li key={key}>{item}</li>
              ))}
            </ul>
          </div>
          <div
            id="payout-note"
            style={{ marginTop: "10px", padding: "10px", fontWeight: 300 }}
          >
            <h2 style={{ fontWeight: "bold", marginBottom: "5px" }}>
              注意事項
            </h2>
            <ul className="notes">
              {listFAQ.map((item, key) => (
                <li key={key}>{item}</li>
              ))}
            </ul>
          </div>
          <nav className={isDisablePayoutFeature() && "disabled"}>
            <button type="submit">払出実行</button>
          </nav>
        </form>
      </main>
      <DialogLoading
        open={payment.loading}
        onClose={handleToggleLoading}
        data={response}
      />
    </>
  );
};

const DialogLoading = (props) => {
  const [payoutWaitingDialogFlag, setPayoutWaitingDialogFlag] = useState();
  const [disabledButtonClose, setDisabledButtonClose] = useState(true);

  const PAYOUT_NOTICE = [
    "払出申請の手続き実行後、通常2営業日以内に指定した口座に振込まれます(振込状況の詳細はお振込先の金融機関よりご確認ください)。",
  ];

  const data = props.data;

  useEffect(() => {
    setDisabledButtonClose(props.open && data.status === PayoutStatus.REQUEST);
    setPayoutWaitingDialogFlag(props.open);

    if (data.status === PayoutStatus.SUCCESS && data.wellnetUrl) {
      setTimeout(function () {
        window.open(data.wellnetUrl, "_blank", "noopener, noreferrer");
      }, 1000);
    }
  }, [props.open, data]);

  const GetSVG = (props) => {
    const data = PayoutDataStatus[props.status];

    return (
      <div
        style={{
          width: "90px",
          textAlign: "center",
        }}
      >
        <img
          src={data.svg}
          width="100%"
          alt={data.label}
          style={{
            ...(props.status === PayoutStatus.REQUEST
              ? { animation: "loading 3s linear infinite" }
              : {}),
            fill: "red",
            height: "100%",
            width: "100%",
          }}
        />
      </div>
    );
  };

  return (
    <>
      <div
        id="modal-overlay"
        className={payoutWaitingDialogFlag ? "modal-view" : ""}
        style={{ opacity: "0.95", zIndex: 100001 }}
      />
      <div
        className={`modal-payout-status dialog ${
          payoutWaitingDialogFlag ? "active" : ""
        }`}
        style={{ zIndex: 100002, height: "auto" }}
      >
        <div className="main-payout-status">
          <div className="status">
            <GetSVG status={data.status} />
          </div>
          <p className="message">{data.message}</p>
          {data.status === PayoutStatus.REQUEST ? (
            <div id="payout-notice" style={{ textAlign: "left", margin: "0" }}>
              <ul className="notes">
                {PAYOUT_NOTICE.map((note, index) => (
                  <li key={index}>{note}</li>
                ))}
              </ul>
            </div>
          ) : (
            ""
          )}
          <a
            role="button"
            onClick={(e) => {
              if (data.status !== PayoutStatus.SUCCESS) {
                e.preventDefault();
                props.onClose();
              }
            }}
            href="/payout"
            style={{
              background: disabledButtonClose
                ? "#cccccc"
                : `${PayoutDataStatus[data.status].color}`,
              cursor: disabledButtonClose ? "no-drop" : "pointer",
            }}
          >
            戻る
          </a>
        </div>
      </div>
    </>
  );
};

export default PayoutForm;
