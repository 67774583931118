export const isDisablePayoutFeature = () => {
  const storedData = JSON.parse(sessionStorage.getItem("ARTISTOCK_FRONT_DATA"));
  const customerEnv = process.env.REACT_APP_PAYOUT_CUSTOMER_ID;
  const customer = customerEnv ? customerEnv.split(",") : [];

  return (
    process.env.REACT_APP_DISABLE_PAYOUT_FEATURE === "true" &&
    !customer.includes(atob(storedData.accessToken))
  );
};
