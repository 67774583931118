import Header from "@components/Header";
import Footer from "@components/Footer";
import { useLocation, Outlet } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { Context } from "./GlobalState";
const Layout = () => {
  const { state } = useContext(Context);
  const location = useLocation();

  const [streamDialogFlag, setstreamDialogFlag] = useState(false);
  const [
    CollectionDetailstreamDialogFlag,
    setCollectionDetailstreamDialogFlag,
  ] = useState(false);
  const [royaltyDialogFlag, setroyaltyDialogFlag] = useState(false);
  const [songRoyaltyDialogFlag, setSongRoyaltyDialogFlag] = useState(false);
  const [holdingsDialogFlag, setholdingsDialogFlag] = useState(false);
  const [custodyDialogFlag, setcustodyDialogFlag] = useState(false);
  const [payoutDialogFlag, setpayoutDialogFlag] = useState(false);

  function ShowStreamDialog() {
    document.body.classList.add("dialog");
    setstreamDialogFlag(true);
  }

  function HideStreamDialog() {
    document.body.classList.remove("dialog");
    setstreamDialogFlag(false);
  }

  function ShowCollectionDetailStreamDialog() {
    document.body.classList.add("dialog");
    setCollectionDetailstreamDialogFlag(true);
  }

  function HideCollectionDetailStreamDialog() {
    document.body.classList.remove("dialog");
    setCollectionDetailstreamDialogFlag(false);
  }

  function ShowRoyaltyDialog() {
    document.body.classList.add("dialog");
    setroyaltyDialogFlag(true);
  }

  function HideRoyaltyDialog() {
    document.body.classList.remove("dialog");
    setroyaltyDialogFlag(false);
  }

  function ShowSongRoyaltyDialog() {
    document.body.classList.add("dialog");
    setSongRoyaltyDialogFlag(true);
  }

  function HideSongRoyaltyDialog() {
    document.body.classList.remove("dialog");
    setSongRoyaltyDialogFlag(false);
  }

  function ShowHoldingsDialog() {
    document.body.classList.add("dialog");
    setholdingsDialogFlag(true);
  }

  function HideHoldingsDialog() {
    document.body.classList.remove("dialog");
    setholdingsDialogFlag(false);
  }

  function ShowCustodyDialog() {
    document.body.classList.add("dialog");
    setcustodyDialogFlag(true);
  }

  function HideCustodyDialog() {
    document.body.classList.remove("dialog");
    setcustodyDialogFlag(false);
  }

  function ShowPayoutDialog() {
    document.body.classList.add("dialog");
    setpayoutDialogFlag(true);
  }

  function HidePayoutDialog() {
    document.body.classList.remove("dialog");
    setpayoutDialogFlag(false);
  }

  useEffect(() => {
    if (
      state.accessToken === null &&
      location.pathname.split("/")[1] !== "authenticate"
    ) {
      window.location.replace(
        process.env.REACT_APP_OIKOS_URL + "account/login"
      );
    }
  }, [state.accessToken, location.pathname]);
  return (
    <>
      <div
        id="wrap"
        className={
          location.pathname === "/" ? "collection content" : "collection-detail"
        }
      >
        <Header />
        <Outlet
          context={[
            ShowStreamDialog,
            ShowCollectionDetailStreamDialog,
            ShowRoyaltyDialog,
            ShowSongRoyaltyDialog,
            ShowHoldingsDialog,
            ShowCustodyDialog,
            ShowPayoutDialog,
          ]}
        />
        <Footer />
      </div>

      <div className={streamDialogFlag ? "dialog active" : "dialog"}>
        <div className="container">
          <div className="inner">
            <p>
              毎月、収益分配の金額が確定すると「預かり金総額」に加算されます。
            </p>
            <p>
              確定した収益分配の金額の払い出し開始日までは、約1ヶ月の期間を頂いております。
            </p>
            <p>
              払出の準備が整いましたら、「払出可能金額」に金額が加算され表示されます。
            </p>
            <p>
              払出方法は、ご指定の銀行口座への振り込みになります。
            </p>
          </div>
        </div>
        <button onClick={() => HideStreamDialog()} />
      </div>

      <div
        className={
          CollectionDetailstreamDialogFlag ? "dialog active" : "dialog"
        }
      >
        <div className="container">
          <div className="inner">
            <p>
              あなたが保有する楽曲の、全期間のストリーミング再生回数の推移。
            </p>
            <p>
              楽曲オーナーは、配信開始日から最終更新日までの全期間のストリーミング再生回数を確認できます。ストリーミング再生回数は、グラフを操作することで日別の数字も確認できます。
            </p>
          </div>
        </div>
        <button onClick={() => HideCollectionDetailStreamDialog()} />
      </div>

      <div className={royaltyDialogFlag ? "dialog active" : "dialog"}>
        <div className="container">
          <div className="inner">
            <p>あなたが保有する全楽曲から受取った、収益分配金額の合計。</p>
            <p>
              楽曲はストリーミング再生回数に応じて収益を生み出します。楽曲が生み出す収益を、保有する収益分配率に応じて楽曲オーナーに分配します。収益分配は1ヶ月に1度実施します。個別の楽曲から受け取った収益金額の確認は「内訳・払出申請」ページよりご確認できます。
            </p>
          </div>
        </div>
        <button onClick={() => HideRoyaltyDialog()} />
      </div>

      <div className={songRoyaltyDialogFlag ? "dialog active" : "dialog"}>
        <div className="container">
          <div className="inner">
            <p>あなたが保有する楽曲の、全期間の収益実績データの推移。</p>
            <p>
              楽曲オーナーは、配信開始日から最終更新日までの全期間の楽曲が生み出した収益実績の金額を確認できます。楽曲が生み出す収益を、保有する収益分配率に応じて楽曲オーナーに分配します。楽曲オーナーは、自らがOIKOSを保有する前の収益実績データの推移も確認できます。
            </p>
          </div>
        </div>
        <button onClick={() => HideSongRoyaltyDialog()} />
      </div>

      <div className={holdingsDialogFlag ? "dialog active" : "dialog"}>
        <div className="container">
          <div className="inner">
            <p>あなたが保有するOIKOSの資産額の合計。</p>
            <p>
              OIKOSを保有することで、アーティストとファンが共に楽曲のオーナーになります。楽曲のオーナーは、保有するOIKOSに表示される収益分配率に応じて、ロイヤリティーを受取ることができます。また、今後ファン同士で保有するOIKOSを売買できるファンマーケットプレイスの開設を予定しております。
            </p>
          </div>
        </div>
        <button onClick={() => HideHoldingsDialog()} />
      </div>

      <div className={custodyDialogFlag ? "dialog active" : "dialog"}>
        <div className="container">
          <div className="inner">
            <p>あなたが保有している収益金額です。</p>
            <p>
              毎月、収益分配の金額が確定すると「預かり金総額」に加算されます。確定した収益分配の金額の払い出し開始日までは、約1ヶ月の期間を頂いております。払出の準備が整いましたら、「払出可能金額」に金額が加算され表示されます。また、個別の楽曲から受け取った収益金額の確認は「内訳・払出」よりご確認ください。
            </p>
          </div>
        </div>
        <button onClick={() => HideCustodyDialog()} />
      </div>

      <div className={payoutDialogFlag ? "dialog active" : "dialog"}>
        <div className="container">
          <div className="inner">
            <p>
              払出申請は「払出可能金額」が¥3,000以上になりますと申請が行えます。現在、払出システムの準備をしておりますので、いましばらくお待ちください。
            </p>
            <p>
              毎月、収益分配の金額が確定すると「預かり金総額」に加算されます。確定した収益分配の金額の払い出し開始日までは、約1ヶ月の期間を頂いております。払出の準備が整いましたら、「払出可能金額」に金額が加算され表示されます。払出方法は、ご指定の銀行口座への振り込みになります。
            </p>
          </div>
        </div>
        <button onClick={() => HidePayoutDialog()} />
      </div>
    </>
  );
};

export default Layout;
