/* eslint-disable jsx-a11y/anchor-is-valid */
import { useQuery } from "@shopify/react-graphql";
import gql from "graphql-tag";
import React, { useState } from "react";
import { useNavigate, useOutletContext } from "react-router-dom";
import {
  DEPOSIT_REFUND_MESSAGE,
  DepositType,
  PayoutStatus,
} from "../services/const";
import { isDisablePayoutFeature } from "../utils/base64";
const Payout = () => {
  let navigate = useNavigate();
  const [ShowPayoutDialog] = useOutletContext();

  const DEPOSITS_SHOWCOUNT = 12;
  const [deposits_showingcount, setdeposits_showingcount] = useState(12);

  const GET_CUSTODY = gql`
    query graphql {
      totalAndPayableAmount {
        totalAmount
        payableAmount
      }
    }
  `;

  const GET_DEPOSITS = gql`
    query GetDeposits {
      deposits {
        amount
        fee
        coyoyakunum
        message
        status
        createdAt
        details {
          album {
            artist {
              name
            }
          }
          song {
            title
          }
          amount
          createdAt
        }
        reflectionMonth
        depositType
      }
    }
  `;

  const {
    loading: getcustody_loading,
    error: getcustody_error,
    data: getcustody_data,
    refetch: getcustody_refetch,
  } = useQuery(GET_CUSTODY);

  const {
    loading: getdeposits_loading,
    error: getdeposits_error,
    data: getdeposits_data,
    refetch: getdeposits_refetch,
  } = useQuery(GET_DEPOSITS);

  function GoToCollection() {
    navigate("/");
  }

  function ConvertDepositCreatedAt(date) {
    return date.split("T")[0].replaceAll("-", ".");
  }

  function ConverDepositAmount(amount, fee) {
    if (fee == null) {
      fee = 0;
    }
    if (amount < 0) {
      return (
        "-¥" +
        Math.abs(Math.floor(amount * 100) / 100 - fee).toLocaleString(
          undefined,
          {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          }
        )
      );
    } else {
      return (
        "¥" +
        (Math.floor(amount * 100) / 100).toLocaleString(undefined, {
          minimumFractionDigits: 0,
          maximumFractionDigits: 2,
        })
      );
    }
  }

  function ShowRevenueSharingDetail(details, reflection_month) {
    return details.map((detail, i) => {
      return (
        <>
          <li>
            <p>
              {detail.album.artist.name} {detail.song.title}{" "}
              {ConvertReflectionMonth(reflection_month)} 収益分配金
            </p>
            <div className="price">
              ¥
              {(Math.floor(detail.amount * 100) / 100).toLocaleString(
                undefined,
                {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                }
              )}
            </div>
          </li>
        </>
      );
    });
  }

  function ConvertReflectionMonth(date) {
    let ymd = date.split("-");
    return ymd[0] + "年" + parseInt(ymd[1]) + "月分";
  }

  function FlipRevenuSharingDetailExpand(num) {
    let ele_a = document.getElementById("deposit-detail-a-" + num);
    let ele_more = document.getElementById("deposit-detail-more-" + num);
    if (ele_more.style.height === "") {
      ele_a.classList.add("opened");
      ele_more.style.height =
        ele_more.getElementsByTagName("ul")[0].clientHeight + "px";
    } else {
      ele_a.classList.remove("opened");
      ele_more.style.height = "";
    }
  }

  function ShowMoreDeposits() {
    if (getdeposits_data.deposits.length > deposits_showingcount) {
      setdeposits_showingcount(deposits_showingcount + DEPOSITS_SHOWCOUNT);
    }
  }

  function ShowPayoutButton(amount) {
    if (amount >= 3000) {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return <a href="/payout-form" data-disable-payout={process.env.REACT_APP_DISABLE_PAYOUT_FEATURE}>払出申請</a>;
    } else {
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      return <a className="disabled" data-disable-payout={process.env.REACT_APP_DISABLE_PAYOUT_FEATURE}>払出申請</a>;
    }
  }

  if (getcustody_loading || getdeposits_loading) {
    return (
      <main className="collection content">
        <div className="inner oikos"></div>
      </main>
    );
  }

  return (
    <main className="payout">
      <div className="inner">
        <div id="payout-title">
          <h1>内訳・払出申請</h1>
        </div>
        <div id="payout-amount">
          <h2>
            払出申請の手続き
            <a onClick={() => ShowPayoutDialog()} className="tips"></a>
          </h2>
          <div className="inner">
            <dl>
              <dt>預かり金総額</dt>
              <dd>
                ¥
                {(
                  Math.floor(
                    getcustody_data.totalAndPayableAmount.totalAmount * 100
                  ) / 100
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
              </dd>
            </dl>
            <dl>
              <dt>払出可能金額</dt>
              <dd>
                ¥
                {getcustody_data.totalAndPayableAmount.payableAmount.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }
                )}
              </dd>
            </dl>
          </div>
          <nav className={isDisablePayoutFeature() && "disabled"} data-disable-payout={process.env.REACT_APP_DISABLE_PAYOUT_FEATURE}
          data-amount={getcustody_data.totalAndPayableAmount.payableAmount}>
            {ShowPayoutButton(
              getcustody_data.totalAndPayableAmount.payableAmount
            )}
          </nav>
        </div>

        <div id="payout-notice">
          <h4>払出申請される前にご確認下さい</h4>
          <ul className="notes">
            <li>払出しは預かり金額総額3,000円以上より受付ます</li>
            <li>払出申請ごとに300円の振込手数料が発生します</li>
            <li>
              払出申請の手続き実行後、通常2営業日以内に指定した口座に振込まれます(振込状況の詳細はお振込先の金融機関よりご確認ください)
            </li>
          </ul>
        </div>

        <div id="payout-detail">
          <h2>預かり金の内訳</h2>
          <div className="inner">
            {getdeposits_data.deposits.length > 0 &&
              getdeposits_data.deposits.map((deposit, i) => {
                return (
                  <>
                    <div
                      className={
                        i + 1 > deposits_showingcount ? "hide" : "detail-item"
                      }
                    >
                      <a
                        className={
                          ![
                            DepositType.REFUND_EXPIRED,
                            DepositType.REFUND,
                          ].includes(deposit.depositType)
                            ? "expand"
                            : ""
                        }
                        id={"deposit-detail-a-" + i}
                        onClick={() => FlipRevenuSharingDetailExpand(i)}
                      >
                        <dl>
                          {deposit.depositType === DepositType.PAYOUT && (
                            <>
                              <dt>
                                {ConvertDepositCreatedAt(deposit.createdAt)}
                              </dt>
                              <dd>払い出し</dd>
                            </>
                          )}
                          {deposit.depositType === DepositType.REFUND && (
                            <>
                              <dt>
                                {ConvertDepositCreatedAt(deposit.createdAt)}
                              </dt>
                              <dd>{DEPOSIT_REFUND_MESSAGE.REFUND}</dd>
                            </>
                          )}
                          {deposit.depositType ===
                            DepositType.REFUND_EXPIRED && (
                            <>
                              <dt>
                                {ConvertDepositCreatedAt(deposit.createdAt)}
                              </dt>
                              <dd>{DEPOSIT_REFUND_MESSAGE.REFUND_EXPIRED}</dd>
                            </>
                          )}
                          {deposit.depositType ===
                            DepositType.REVENUE_SHARING && (
                            <>
                              <dt>
                                {ConvertDepositCreatedAt(deposit.createdAt)}
                              </dt>
                              <dd>
                                {ConvertReflectionMonth(
                                  deposit.reflectionMonth
                                )}
                                収益分配
                              </dd>
                            </>
                          )}
                        </dl>
                        <div
                          className={
                            deposit.amount < 0 ? "price down" : "price up"
                          }
                        >
                          {ConverDepositAmount(deposit.amount, deposit.fee)}
                        </div>
                      </a>
                      <div className="more" id={"deposit-detail-more-" + i}>
                        {deposit.depositType === DepositType.PAYOUT && (
                          <ul>
                            <li>
                              <p>払出金額</p>
                              <div className="price">
                                {ConverDepositAmount(deposit.amount)}
                              </div>
                            </li>
                            <li>
                              <p>振込手数料</p>
                              <div className="price">
                                {ConverDepositAmount(deposit.fee * -1)}
                              </div>
                            </li>
                            {deposit.status === PayoutStatus.ERROR &&
                              deposit.message && (
                                <li>
                                  <p>状態</p>
                                  <div>{deposit.message}</div>
                                </li>
                              )}
                          </ul>
                        )}

                        {deposit.depositType ===
                          DepositType.REVENUE_SHARING && (
                          <ul>
                            {ShowRevenueSharingDetail(
                              deposit.details,
                              deposit.reflectionMonth
                            )}
                          </ul>
                        )}
                      </div>
                    </div>
                  </>
                );
              })}
          </div>

          <nav>
            <a
              className={
                getdeposits_data.deposits.length > deposits_showingcount
                  ? "more"
                  : "more disabled"
              }
              onClick={() => ShowMoreDeposits()}
            >
              SHOW MORE
            </a>
          </nav>
          <div id="payout-note" style={{ marginTop: "50px !important" }}>
            <h2>確認事項</h2>
            <ul className="notes">
              <li>
                確定申告が必要な方は、ご自身で確定申告を行っていただく必要がございます。確定申告についてご不明点がある場合は、税理士・税務署へご相談ください。
              </li>
              <li>
                弊社では支払調書や源泉徴収票は発行しておりません。また、源泉徴収は行っておりませんことをご了承ください。
              </li>
              <li>
                その他、ご不明点は「
                <a
                  href="https://oikosmusic.jp/pages/faq"
                  target="_blank"
                  rel="noreferrer"
                >
                  FAQページ
                </a>
                」及び「
                <a
                  href="https://oikosmusic.jp/policies/terms-of-service"
                  target="_blank"
                  rel="noreferrer"
                >
                  利用規約
                </a>
                」をご確認ください。
              </li>
            </ul>
          </div>
          <nav>
            <a onClick={() => GoToCollection()}>Collection ページに戻る</a>
          </nav>
        </div>
      </div>
    </main>
  );
};

export default Payout;
