import { useMutation } from "@shopify/react-graphql";
import gql from "graphql-tag";
import { useState } from "react";
import { PayoutStatus, sleep, validateEmail } from "./const";

const CREATE_PAYOUT = gql`
  mutation ($email: String!) {
    payoutCreate(input: { email: $email }) {
      message
      wellnetUrl
    }
  }
`;

export const useHookPayout = () => {
  const createPayout = useMutation(CREATE_PAYOUT);

  const [response, setResponse] = useState({
    status: PayoutStatus.ERROR,
    message: "",
    wellnet_url: "",
  });

  const [payment, setPayment] = useState({
    data: {
      id: "",
      email: "",
    },
    errors: [],
    loading: false,
  });

  const handleChangeInput = (data) => {
    let errors = payment.errors.filter((item) => item.key !== data.target.name);

    let forms = {
      ...payment.data,
      [data.target.name]: data.target.value,
    };

    setPayment({ data: { ...forms }, errors: errors });
  };

  const handleFocusInput = (data) => {
    setPayment((prev) => ({
      ...prev,
      errors: prev.errors.filter((item) => item.key !== data.target.name),
    }));
  };

  const validate = () => {
    if (payment.errors.length > 0) return false;

    const data = payment.data;

    let errors = [];

    if (!validateEmail(data.email)) {
      errors.push({ key: "email", value: "電子メールの形式が無効です。" });
    }

    setPayment((prev) => ({ ...prev, errors: errors }));

    return errors.length === 0;
  };

  const handleToggleLoading = () => {
    setPayment((prev) => ({ ...prev, loading: !prev.loading }));
  };

  const handleSubmit = async () => {
    if (!validate()) return;

    setResponse({
      status: PayoutStatus.REQUEST,
      message: "送金依頼中",
    });

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    handleToggleLoading();

    await sleep(1000);

    try {
      const response = await createPayout({
        variables: { email: payment.data.email },
      });

      setResponse({
        message: response.data.payoutCreate.message,
        wellnetUrl: response.data.payoutCreate.wellnetUrl,
        status: PayoutStatus.SUCCESS,
      });
    } catch (error) {
      if (error.message.includes("GraphQL")) {
        const errorMessage = error.message.replace("GraphQL error: ", "");
        setResponse({ message: `${errorMessage}`, status: PayoutStatus.ERROR });
      } else {
        setResponse({
          message: "Internal Server Error",
          status: PayoutStatus.ERROR,
        });
      }
    }
  };

  return {
    payment,
    setPayment,
    response,
    handleChangeInput,
    handleFocusInput,
    handleSubmit,
    handleToggleLoading,
  };
};
