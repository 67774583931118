export const validateEmail = (email) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

// export const validatePasswordPayout = (value) => {
//   const valueRegex = /^[a-zA-Z0-9@._-]{4,20}$/;
//   return valueRegex.test(value);
// };

// export const validateIDPayout = (value) => {
//   const valueRegex = /^[a-zA-Z0-9@._-]{4,20}$/;
//   return valueRegex.test(value);
// };

export const DepositType = {
  REVENUE_SHARING: "revenue_sharing",
  PAYOUT: "payout",
  REFUND: "refund",
  REFUND_EXPIRED: "refund_expired",
};

export const DEPOSIT_REFUND_MESSAGE = {
  REFUND: "振込依頼内容不備のため払い戻し",
  REFUND_EXPIRED: "申請設定期限超過のため払い戻し",
};

export const PayoutStatus = {
  REQUEST: "0",
  SUCCESS: "1",
  ERROR: "2",
  PROCESSING: "3",
};

export const PayoutDataStatus = {
  [PayoutStatus.SUCCESS]: {
    svg: "./img/check.svg",
    color: "#2ecc71",
  },
  [PayoutStatus.ERROR]: {
    svg: "./img/exit.svg",
    color: "#e74c3c",
  },
  [PayoutStatus.REQUEST]: {
    svg: "./img/loading.svg",
    color: "#f1c40f",
  },
  [PayoutStatus.PROCESSING]: {
    svg: "./img/loading.svg",
    color: "#f1c40f",
  },
};

export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
