import { useQuery } from "@shopify/react-graphql";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import React, { useState, useEffect } from "react";
import gql from "graphql-tag";
import CountUp from "react-countup";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";

let Collection_isAllLoaded = false;

const Collection = () => {
  const OWNEDITEMS_SHOWCOUNT = 10;
  const OWNERLIMITEDCONTENT_SHOWCOUNT = 3;
  let navigate = useNavigate();

  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  const chartjs_options_for_Streaming = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 1,
    layout: {
      padding: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      labels: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: function (handler) {
          externalTooltipHandlerForStreaming(handler);
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          color: "#ffffff",
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          color: "#000000",
          align: "center",
          minRotation: 0,
          maxRotation: 0,
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value == 0) {
              return "rgba(255,255,255,0.9)";
            } else {
              return "#333333";
            }
          },
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          count: 3,
          color: "#000000",
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
          callback: function (val, index) {
            if (index != 0) {
              let x = ("" + val).length;
              let p = Math.pow;
              let d = p(10, 1);
              x -= x % 3;
              let rt = Math.round((val * d) / p(10, x)) / d + " KMGTPE"[x / 3];
              if (rt == "0K") {
                rt = "0";
              }
              return rt;
            }
          },
        },
      },
    },
  };

  const chartjs_options_for_Royalty = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 1,
    layout: {
      padding: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      labels: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: function (handler) {
          externalTooltipHandlerForRoyalty(handler);
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          color: "#ffffff",
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          color: "#000000",
          align: "center",
          minRotation: 0,
          maxRotation: 0,
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value == 0) {
              return "rgba(255,255,255,0.9)";
            } else {
              return "#333333";
            }
          },
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          count: 3,
          color: "#000000",
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
          callback: function (val, index) {
            if (index != 0) {
              let x = ("" + val).length;
              let p = Math.pow;
              let d = p(10, 1);
              x -= x % 3;
              let rt = Math.round((val * d) / p(10, x)) / d + " KMGTPE"[x / 3];
              if (rt == "0K") {
                rt = "0";
              }
              return rt;
            }
          },
        },
      },
    },
  };

  const chartjs_options_for_Holdings = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 1,
    layout: {
      padding: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      labels: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: function (handler) {
          externalTooltipHandlerForHoldings(handler);
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          color: "#ffffff",
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          color: "#000000",
          align: "center",
          minRotation: 0,
          maxRotation: 0,
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value == 0) {
              return "rgba(255,255,255,0.9)";
            } else {
              return "#333333";
            }
          },
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          count: 3,
          color: "#000000",
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
          callback: function (val, index) {
            if (index != 0) {
              let x = ("" + val).length;
              let p = Math.pow;
              let d = p(10, 1);
              x -= x % 3;
              let rt = Math.round((val * d) / p(10, x)) / d + " KMGTPE"[x / 3];
              if (rt == "0K") {
                rt = "0";
              }
              return rt;
            }
          },
        },
      },
    },
  };

  const [
    ShowStreamDialog,
    ShowCollectionDetailStreamDialog,
    ShowRoyaltyDialog,
    ShowSongRoyaltyDialog,
    ShowHoldingsDialog,
    ShowCustodyDialog,
    ShowPayoutDialog,
  ] = useOutletContext();

  const GET_ITEMS = gql`
    query GetItems {
      ownedItems {
        id
        totalPrice
        price
        createdAt
        daysOwned
        right {
          id
          createdAt
          isOnSale
          totalPercentage
          owned {
            silver
            gold
            platinum
            custom
          }
          song {
            title
          }
          album {
            title
          }
          artist {
            name
          }
          token {
            serialCode
          }
          thumbnail {
            url
          }
        }
      }
      soldItems {
        id
        createdAt
        daysOwned
        right {
          id
          createdAt
          isOnSale
          totalPercentage
          owned {
            silver
            gold
            platinum
            custom
          }
          song {
            title
          }
          album {
            title
          }
          artist {
            name
          }
          token {
            serialCode
          }
          thumbnail {
            url
          }
        }
      }
      currentUser {
        owned {
          silver
          gold
          platinum
          custom
        }
      }
    }
  `;

  const GET_OWNERLIMITEDCONTENT = gql`
    query {
      oikosClubArticles {
        handle
        publishedAt
        tags
        title
      }
    }
  `;

  const [currentTab, setCurrentTab] = useState("owned");

  const [ownedItems_showingcount, setownedItems_showingcount] = useState(10);
  const [
    ownerlimitedcontent_showingcount,
    setownerlimitedcontent_showingcount,
  ] = useState(3);

  const [playcount_period, setplaycount_period] = useState(0);
  const [royalty_period, setroyalty_period] = useState(0);
  const [holdings_period, setholdings_period] = useState(0);

  const [playcount7daysselected, setplaycount7daysselected] = useState(false);
  const [playcount28daysselected, setplaycount28daysselected] = useState(false);
  const [playcount90daysselected, setplaycount90daysselected] = useState(false);
  const [playcount180daysselected, setplaycount180daysselected] =
    useState(false);
  const [playcount365daysselected, setplaycount365daysselected] =
    useState(false);
  const [playcountalldaysselected, setplaycountalldaysselected] =
    useState(false);

  const [royalty90daysselected, setroyalty90daysselected] = useState(false);
  const [royalty180daysselected, setroyalty180daysselected] = useState(false);
  const [royalty365daysselected, setroyalty365daysselected] = useState(false);
  const [royaltyalldaysselected, setroyaltyalldaysselected] = useState(false);

  const [holdings90daysselected, setholdings90daysselected] = useState(false);
  const [holdings180daysselected, setholdings180daysselected] = useState(false);
  const [holdings365daysselected, setholdings365daysselected] = useState(false);
  const [holdingsalldaysselected, setholdingsalldaysselected] = useState(false);

  const [royaltytabselected, setroyaltytabselected] = useState(false);
  const [holdingstabselected, setholdingstabselected] = useState(false);

  const { loading, error, data } = useQuery(GET_ITEMS);
  const {
    loading: getownerlimitedcontent_loading,
    error: getownerlimitedcontent_error,
    data: getownerlimitedcontent_data,
  } = useQuery(GET_OWNERLIMITEDCONTENT);

  const GET_PLAYCOUNT = gql`
    query graphql($period: Int!) {
      totalPlayCount(period: $period) {
        playCount
      }
    }
  `;

  const {
    loading: getplaycount_loading,
    error: getplaycount_error,
    data: getplaycount_data,
    refetch: getplaygcount_refetch,
  } = useQuery(GET_PLAYCOUNT, {
    variables: { period: 6 },
  });

  const GET_PLAYCOUNTCHANGERATE = gql`
    query graphql($period: Int!) {
      ratePlayCountChange(period: $period) {
        changeRate
      }
    }
  `;

  const {
    loading: getplaycountchangerate_loading,
    error: getplaycountchangerate_error,
    data: getplaycountchangerate_data,
    refetch: getplaygcountchangerate_refetch,
  } = useQuery(GET_PLAYCOUNTCHANGERATE, {
    variables: { period: 6 },
  });

  const GET_PLAYCOUNTGRAPH = gql`
    query graphql($period: Int!) {
      totalPlayCountPerDay(period: $period) {
        date
        playCount
      }
    }
  `;

  const {
    loading: getplaycountgraph_loading,
    error: getplaycountgraph_error,
    data: getplaycountgraph_data,
    refetch: getplaygcountgraph_refetch,
  } = useQuery(GET_PLAYCOUNTGRAPH, {
    variables: { period: 6 },
  });

  const GET_ROYALTY = gql`
    query graphql($period: Int!) {
      totalEarnings(period: $period) {
        earnings
        passedFirstReflection
      }
    }
  `;

  const {
    loading: getroyalty_loading,
    error: getroyalty_error,
    data: getroyalty_data,
    refetch: getroyalty_refetch,
  } = useQuery(GET_ROYALTY, {
    variables: { period: 89 },
  });

  const GET_ROYALTYCHANGERATE = gql`
    query graphql($period: Int!) {
      rateEarningsChangeAndTotalPerMonth(period: $period) {
        earnings {
          date
          earnings
        }
        changeRate
      }
    }
  `;

  const {
    loading: getroyaltychangerate_loading,
    error: getroyaltychangerate_error,
    data: getroyaltychangerate_data,
    refetch: getroyaltychangerate_refetch,
  } = useQuery(GET_ROYALTYCHANGERATE, {
    variables: { period: 89 },
  });

  const GET_ROYALTYGRAPH = gql`
    query graphql($period: Int!) {
      rateEarningsChangeAndTotalPerMonth(period: $period) {
        earnings {
          date
          earnings
        }
        changeRate
      }
    }
  `;

  const {
    loading: getroyaltygraph_loading,
    error: getroyaltygraph_error,
    data: getroyaltygraph_data,
    refetch: getroyaltygraph_refetch,
  } = useQuery(GET_ROYALTYGRAPH, {
    variables: { period: 89 },
  });

  const GET_HOLDINGS = gql`
    query graphql($period: Int!) {
      totalPrice(period: $period) {
        price
      }
    }
  `;

  const {
    loading: getholdings_loading,
    error: getholdings_error,
    data: getholdings_data,
    refetch: getholdings_refetch,
  } = useQuery(GET_HOLDINGS, {
    variables: { period: 89 },
  });

  const GET_HOLDINGSGRAPH = gql`
    query graphql($period: Int!) {
      totalPricePerDay(period: $period) {
        date
        price
      }
    }
  `;

  const {
    loading: getholdingsgraph_loading,
    error: getholdingsgraph_error,
    data: getholdingsgraph_data,
    refetch: getholdingsgraph_refetch,
  } = useQuery(GET_HOLDINGSGRAPH, {
    variables: { period: 89 },
  });

  const GET_CUSTODY = gql`
    query graphql {
      totalAndPayableAmount {
        totalAmount
        payableAmount
      }
    }
  `;

  const {
    loading: getcustody_loading,
    error: getcustody_error,
    data: getcustody_data,
    refetch: getcustody_refetch,
  } = useQuery(GET_CUSTODY);

  function ShowPlayCount(period) {
    setplaycount_period(period);

    if (period == 7) {
      setplaycount7daysselected(true);
      setplaycount28daysselected(false);
      setplaycount90daysselected(false);
      setplaycount180daysselected(false);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(false);
    } else if (period == 28) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(true);
      setplaycount90daysselected(false);
      setplaycount180daysselected(false);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(false);
    } else if (period == 90) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(false);
      setplaycount90daysselected(true);
      setplaycount180daysselected(false);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(false);
    } else if (period == 180) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(false);
      setplaycount90daysselected(false);
      setplaycount180daysselected(true);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(false);
    } else if (period == 365) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(false);
      setplaycount90daysselected(false);
      setplaycount180daysselected(false);
      setplaycount365daysselected(true);
      setplaycountalldaysselected(false);
    } else if (period == 0) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(false);
      setplaycount90daysselected(false);
      setplaycount180daysselected(false);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(true);
    }

    let tmp_period;

    if (period == 0) {
      tmp_period = period;
    } else {
      tmp_period = period - 1;
    }

    getplaygcount_refetch({ period: tmp_period });
    getplaygcountgraph_refetch({ period: tmp_period });
    getplaygcountchangerate_refetch({ period: tmp_period });
  }

  function ShowRoyalty(period) {
    setroyalty_period(period);

    if (period == 90) {
      setroyalty90daysselected(true);
      setroyalty180daysselected(false);
      setroyalty365daysselected(false);
      setroyaltyalldaysselected(false);
    } else if (period == 180) {
      setroyalty90daysselected(false);
      setroyalty180daysselected(true);
      setroyalty365daysselected(false);
      setroyaltyalldaysselected(false);
    } else if (period == 365) {
      setroyalty90daysselected(false);
      setroyalty180daysselected(false);
      setroyalty365daysselected(true);
      setroyaltyalldaysselected(false);
    } else if (period == 0) {
      setroyalty90daysselected(false);
      setroyalty180daysselected(false);
      setroyalty365daysselected(false);
      setroyaltyalldaysselected(true);
    }

    let tmp_period;

    if (period == 0) {
      tmp_period = period;
    } else {
      tmp_period = period - 1;
    }

    getroyalty_refetch({ period: tmp_period });
    getroyaltygraph_refetch({ period: tmp_period });
    getroyaltychangerate_refetch({ period: tmp_period });
  }

  function ShowHoldings(period) {
    setholdings_period(period);

    if (period == 90) {
      setholdings90daysselected(true);
      setholdings180daysselected(false);
      setholdings365daysselected(false);
      setholdingsalldaysselected(false);
    } else if (period == 180) {
      setholdings90daysselected(false);
      setholdings180daysselected(true);
      setholdings365daysselected(false);
      setholdingsalldaysselected(false);
    } else if (period == 365) {
      setholdings90daysselected(false);
      setholdings180daysselected(false);
      setholdings365daysselected(true);
      setholdingsalldaysselected(false);
    } else if (period == 0) {
      setholdings90daysselected(false);
      setholdings180daysselected(false);
      setholdings365daysselected(false);
      setholdingsalldaysselected(true);
    }

    let tmp_period;

    if (period == 0) {
      tmp_period = period;
    } else {
      tmp_period = period - 1;
    }

    getholdings_refetch({ period: tmp_period });
    getholdingsgraph_refetch({ period: tmp_period });
  }

  function ShowStrPlayCountChangeRate() {
    if (
      typeof getplaycountchangerate_error === "undefined" &&
      getplaycountchangerate_data.ratePlayCountChange.changeRate !== null
    ) {
      return (
        <>
          {ShowUpDown(
            getplaycountchangerate_data.ratePlayCountChange.changeRate
          )}
          過去{ConvertTerm(playcount_period)}で
          {Math.ceil(
            getplaycountchangerate_data.ratePlayCountChange.changeRate * 100
          )}
          %変化しました
        </>
      );
    } else {
      return <>※まだデータがありません</>;
    }
  }

  function ShowStrRoyaltyChangeRate() {
    if (
      getroyaltychangerate_data.rateEarningsChangeAndTotalPerMonth.earnings
        .length === 0
    ) {
      return <>※まだデータがありません</>;
    } else if (getroyalty_data.totalEarnings.passedFirstReflection === false) {
      return (
        <>※購入したOIKOSの「初回分配日」を迎えるとデータが更新されます。</>
      );
    } else {
      return (
        <>
          {ShowUpDown(
            getroyaltychangerate_data.rateEarningsChangeAndTotalPerMonth
              .changeRate
          )}
          過去{ConvertTerm(royalty_period)}で
          {Math.ceil(
            getroyaltychangerate_data.rateEarningsChangeAndTotalPerMonth
              .changeRate * 100
          )}
          %変化しました
        </>
      );
    }
  }

  function ShowStrHoldingsChangeRate() {
    if (getholdingsgraph_data.totalPricePerDay.length === 0) {
      return <>※まだデータがありません</>;
    } else {
      return (
        <>
          <font class="up">▲</font>過去{ConvertTerm(holdings_period)}で
          {GetHoldingsChangeRate()}
          %変化しました
        </>
      );
    }
  }

  function ShowUpDown(rate) {
    if (rate < 0) {
      return (
        <>
          <font class="down">▼</font>
        </>
      );
    } else {
      return (
        <>
          <font class="up">▲</font>
        </>
      );
    }
  }

  function GetHoldingsChangeRate() {
    let rate = 0;
    if (
      getholdingsgraph_data.totalPricePerDay.length > 0 &&
      getholdingsgraph_data.totalPricePerDay[0].price > 0
    )
      rate =
        (getholdingsgraph_data.totalPricePerDay[
          getholdingsgraph_data.totalPricePerDay.length - 1
        ].price -
          getholdingsgraph_data.totalPricePerDay[0].price) /
        getholdingsgraph_data.totalPricePerDay[0].price;
    return Math.ceil(rate * 100);
  }

  function ConvertTerm(period) {
    if (period == 0) {
      return "全期間";
    } else {
      return period + "日";
    }
  }

  function ShowTab(tab) {
    if (tab == "royalty") {
      setroyaltytabselected(true);
      setholdingstabselected(false);
      ShowRoyalty(999);
      ShowRoyalty(royalty_period);
    } else if (tab == "holdings") {
      setroyaltytabselected(false);
      setholdingstabselected(true);
      ShowHoldings(999);
      ShowHoldings(holdings_period);
    }
  }

  function GoToPayout() {
    Collection_isAllLoaded = false;
    navigate("/payout");
  }

  function ShowMoreOwnedItems() {
    if (data.ownedItems.length > ownedItems_showingcount) {
      setownedItems_showingcount(
        ownedItems_showingcount + OWNEDITEMS_SHOWCOUNT
      );
    }
  }

  function ShowMoreOwnerLimitedContent() {
    if (
      getownerlimitedcontent_data.oikosClubArticles.length >
      ownerlimitedcontent_showingcount
    ) {
      setownerlimitedcontent_showingcount(
        ownerlimitedcontent_showingcount + OWNERLIMITEDCONTENT_SHOWCOUNT
      );
    }
  }

  function CreateStreamingData(data) {
    let labels = [];
    data.totalPlayCountPerDay.map((d) => {
      if (window.innerWidth >= 1024) {
        labels.push(d.date.replaceAll("-", "/"));
      } else {
        let date = d.date.split("-");
        labels.push(parseInt(date[1]) + "/" + parseInt(date[2]));
      }
    });
    let dt = [];
    data.totalPlayCountPerDay.map((d) => {
      dt.push(d.playCount);
    });

    return {
      labels,
      datasets: [
        {
          label: "",
          tension: 0,
          data: dt,
          borderColor: "rgb(89, 50, 255)",
          backgroundColor: "rgba(89, 50, 255, 0.2)",
          fill: true,
        },
      ],
    };
  }

  function CreateRoyaltyData(data) {
    if (getroyalty_data.totalEarnings.passedFirstReflection == false) {
      return {
        labels: [],
        datasets: [
          {
            label: "",
            tension: 0,
            data: [],
            borderColor: "rgb(89, 50, 255)",
            backgroundColor: "rgba(89, 50, 255, 0.2)",
            fill: true,
          },
        ],
      };
    }

    let labels = [];
    data.rateEarningsChangeAndTotalPerMonth.earnings.map((d) => {
      if (window.innerWidth >= 1024) {
        labels.push(d.date.replaceAll("-", "/"));
      } else {
        let date = d.date.split("-");
        labels.push(parseInt(date[1]) + "/" + parseInt(date[2]));
      }
    });
    let dt = [];
    data.rateEarningsChangeAndTotalPerMonth.earnings.map((d) => {
      dt.push(d.earnings);
    });

    return {
      labels,
      datasets: [
        {
          label: "",
          tension: 0,
          data: dt,
          borderColor: "rgb(89, 50, 255)",
          backgroundColor: "rgba(89, 50, 255, 0.2)",
          fill: true,
        },
      ],
    };
  }

  function CreateHoldingsData(data) {
    let labels = [];
    data.totalPricePerDay.map((d) => {
      if (window.innerWidth >= 1024) {
        labels.push(d.date.replaceAll("-", "/"));
      } else {
        let date = d.date.split("-");
        labels.push(parseInt(date[1]) + "/" + parseInt(date[2]));
      }
    });
    let dt = [];
    data.totalPricePerDay.map((d) => {
      dt.push(d.price);
    });

    return {
      labels,
      datasets: [
        {
          label: "",
          tension: 0,
          data: dt,
          borderColor: "rgb(89, 50, 255)",
          backgroundColor: "rgba(89, 50, 255, 0.2)",
          fill: true,
        },
      ],
    };
  }

  function GetWidthForGraph() {
    if (window.innerWidth >= 1024) {
      return 886;
    } else {
      return 330;
    }
  }

  function GetHeightForGraph() {
    if (window.innerWidth >= 1024) {
      return 300;
    } else {
      return 280;
    }
  }

  function externalTooltipHandlerForStreaming(context) {
    var chart = context.chart;
    var tooltip = context.tooltip;

    var el = getTooltipForStreaming();

    if (tooltip.opacity === 0) {
      el.style.opacity = 0;
      return;
    }

    var date = tooltip.title[0];
    var ary = [];
    date.split("/").map((val) => {
      ary.push(Number(val));
    });

    var title = ary.join("<i>/</i>");
    var value = tooltip.body[0].lines[0];

    document.querySelector(".collection-streaming-chart-tip div").innerHTML =
      "<span>" + title + "</span><br><span>" + value + "</span>";

    var { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    el.style.opacity = 1;
    el.style.left = positionX + tooltip.caretX + "px";
    document.querySelector(
      ".collection-streaming-chart-tip-line"
    ).style.height = tooltip.caretY + 20 + "px";
  }

  function externalTooltipHandlerForRoyalty(context) {
    var chart = context.chart;
    var tooltip = context.tooltip;

    var el = getTooltipForRoyalty();

    if (tooltip.opacity === 0) {
      el.style.opacity = 0;
      return;
    }

    var date = tooltip.title[0];
    var ary = [];
    date.split("/").map((val) => {
      ary.push(Number(val));
    });

    var title = ary.join("<i>/</i>");
    var value = tooltip.body[0].lines[0];

    document.querySelector(".collection-royalty-chart-tip div").innerHTML =
      "<span>" + title + "</span><br><span>¥" + value + "</span>";

    var { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    el.style.opacity = 1;
    el.style.left = positionX + tooltip.caretX + "px";
    document.querySelector(".collection-royalty-chart-tip-line").style.height =
      tooltip.caretY + 20 + "px";
  }

  function externalTooltipHandlerForHoldings(context) {
    var chart = context.chart;
    var tooltip = context.tooltip;

    var el = getTooltipForHoldings();

    if (tooltip.opacity === 0) {
      el.style.opacity = 0;
      return;
    }

    var date = tooltip.title[0];
    var ary = [];
    date.split("/").map((val) => {
      ary.push(Number(val));
    });

    var title = ary.join("<i>/</i>");
    var value = tooltip.body[0].lines[0];

    document.querySelector(".collection-holdings-chart-tip div").innerHTML =
      "<span>" + title + "</span><br><span>¥" + value + "</span>";

    var { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    el.style.opacity = 1;
    el.style.left = positionX + tooltip.caretX + "px";
    document.querySelector(".collection-holdings-chart-tip-line").style.height =
      tooltip.caretY + 20 + "px";
  }

  function getTooltipForStreaming() {
    var tooltip = document.querySelector(".collection-streaming-chart-tip");
    if (!tooltip) {
      tooltip = document.createElement("div");
      tooltip.className = "collection-streaming-chart-tip";

      var label = document.createElement("div");
      var line = document.createElement("span");
      line.className = "collection-streaming-chart-tip-line";
      tooltip.appendChild(label);
      tooltip.appendChild(line);
      document
        .querySelector(".collection-streaming-chart-box")
        .appendChild(tooltip);
    }
    return tooltip;
  }

  function getTooltipForRoyalty() {
    var tooltip = document.querySelector(".collection-royalty-chart-tip");
    if (!tooltip) {
      tooltip = document.createElement("div");
      tooltip.className = "collection-royalty-chart-tip";

      var label = document.createElement("div");
      var line = document.createElement("span");
      line.className = "collection-royalty-chart-tip-line";
      tooltip.appendChild(label);
      tooltip.appendChild(line);
      document
        .querySelector(".collection-royalty-chart-box")
        .appendChild(tooltip);
    }
    return tooltip;
  }

  function getTooltipForHoldings() {
    var tooltip = document.querySelector(".collection-holdings-chart-tip");
    if (!tooltip) {
      tooltip = document.createElement("div");
      tooltip.className = "collection-holdings-chart-tip";

      var label = document.createElement("div");
      var line = document.createElement("span");
      line.className = "collection-holdings-chart-tip-line";
      tooltip.appendChild(label);
      tooltip.appendChild(line);
      document
        .querySelector(".collection-holdings-chart-box")
        .appendChild(tooltip);
    }
    return tooltip;
  }

  function ShowHoldingsTotalPrice(data) {
    if (data.totalPricePerDay.length > 0) {
      return data.totalPricePerDay[data.totalPricePerDay.length - 1].price;
    } else {
      return 0;
    }
  }

  useEffect(() => {
    ShowPlayCount(7);
    ShowTab("royalty");
    ShowRoyalty(90);
    ShowHoldings(90);
  }, []);

  if (
    Collection_isAllLoaded == false &&
    (loading ||
      getplaycount_loading ||
      getplaycountgraph_loading ||
      getplaycountchangerate_loading ||
      getroyalty_loading ||
      getroyaltygraph_loading ||
      getroyaltychangerate_loading ||
      getholdings_loading ||
      getholdingsgraph_loading ||
      getcustody_loading ||
      getownerlimitedcontent_loading)
  ) {
    return (
      <main className="collection content">
        <div className="inner oikos"></div>
      </main>
    );
  }
  if (error) {
    return (
      <main className="collection content">
        <div className="inner not-found">
          <p>コレクションページを開くにはログインが必要です。</p>
          <div className="function">
            <a href={process.env.REACT_APP_OIKOS_URL + "account/login"}>
              LOGINへ
            </a>
          </div>
        </div>
      </main>
    );
  }

  Collection_isAllLoaded = true;

  const onTabClick = (event) => {
    let selectedTab = event.target.dataset.type;
    if (currentTab !== selectedTab) setCurrentTab(selectedTab);
  };
  return (
    <main className="collection content">
      <div className="inner">
        <h1 className="large">Collection</h1>
        <div className="content-label">
          <img src="img/typo_collection.svg" alt="" />
        </div>
        <div className="oikos">
          <ul>
            <li>
              <div className="bar">
                <img src="img/bar_silver2.png?2" alt="" />
              </div>
              <div className="count">
                {data.currentUser.owned.silver || "0"}
              </div>
            </li>
            <li>
              <div className="bar">
                <img src="img/bar_gold2.png?2" alt="" />
              </div>
              <div className="count">{data.currentUser.owned.gold || "0"}</div>
            </li>
            <li>
              <div className="bar">
                <img src="img/bar_platinum2.png?2" alt="" />
              </div>
              <div className="count">
                {data.currentUser.owned.platinum || "0"}
              </div>
            </li>
          </ul>
        </div>

        {data.ownedItems.length === 0 && (
          <div className="no-item-bought">
            <div className="inner not-found">
              <p>
                購入済みの商品はありません。
                <br />
                OIKOSを購入して、お気に入りのアーティストを応援しよう。
              </p>
              <div className="function">
                <a href={process.env.REACT_APP_OIKOS_URL + "collections/all"}>
                  商品を購入する
                </a>
              </div>
            </div>
          </div>
        )}

        <div className="playcount-area">
          <div className="title">
            ストリーミング再生数
            <a onClick={() => ShowStreamDialog()} className="tips"></a>
          </div>
          <div className="playcount">
            <CountUp
              end={getplaycount_data.totalPlayCount.playCount}
              decimal=","
            />
          </div>
          <div className="changerate">{ShowStrPlayCountChangeRate()}</div>

          <div class="collection-streaming-chart-box">
            <Line
              width={GetWidthForGraph()}
              height={GetHeightForGraph()}
              options={chartjs_options_for_Streaming}
              data={CreateStreamingData(getplaycountgraph_data)}
            />
          </div>

          <div className="playcount-period-selection-area">
            <ul>
              <li>
                <a
                  onClick={() => ShowPlayCount(7)}
                  className={playcount7daysselected ? "active" : ""}
                >
                  7日
                </a>
              </li>
              <li>
                <a
                  onClick={() => ShowPlayCount(28)}
                  className={playcount28daysselected ? "active" : ""}
                >
                  28日
                </a>
              </li>
              <li>
                <a
                  onClick={() => ShowPlayCount(90)}
                  className={playcount90daysselected ? "active" : ""}
                >
                  90日
                </a>
              </li>
              <li>
                <a
                  onClick={() => ShowPlayCount(180)}
                  className={playcount180daysselected ? "active" : ""}
                >
                  180日
                </a>
              </li>
              <li>
                <a
                  onClick={() => ShowPlayCount(365)}
                  className={playcount365daysselected ? "active" : ""}
                >
                  365日
                </a>
              </li>
              <li>
                <a
                  onClick={() => ShowPlayCount(0)}
                  className={playcountalldaysselected ? "active" : ""}
                >
                  全期間
                </a>
              </li>
            </ul>
          </div>
        </div>

        <div className="royalty-and-holdings">
          <div className="tab">
            <a
              onClick={() => ShowTab("royalty")}
              className={royaltytabselected ? "active" : ""}
            >
              ロイヤリティー
            </a>
            <a
              onClick={() => ShowTab("holdings")}
              className={holdingstabselected ? "active" : ""}
            >
              保有OIKOS総額
            </a>
          </div>
          <div
            className={
              royaltytabselected ? "chart-royalty active" : "chart-royalty"
            }
          >
            <div className="title">
              ロイヤリティー
              <a onClick={() => ShowRoyaltyDialog()} className="tips"></a>
            </div>
            <div className="royalty">
              ¥
              <CountUp
                end={
                  getroyalty_data.totalEarnings.passedFirstReflection == false
                    ? 0
                    : Math.floor(getroyalty_data.totalEarnings.earnings * 100) /
                      100
                }
                decimal="."
                decimals={
                  (Math.floor(getroyalty_data.totalEarnings.earnings * 100) /
                    100) %
                    1 !==
                  0
                    ? 2
                    : 0
                }
              />
            </div>
            <div className="changerate">{ShowStrRoyaltyChangeRate()}</div>

            <div class="collection-royalty-chart-box">
              <Line
                width={GetWidthForGraph()}
                height={GetHeightForGraph()}
                options={chartjs_options_for_Royalty}
                data={CreateRoyaltyData(getroyaltygraph_data)}
              />
            </div>

            <div className="royalty-period-selection-area">
              <ul>
                <li>
                  <a
                    onClick={() => ShowRoyalty(90)}
                    className={royalty90daysselected ? "active" : ""}
                  >
                    90日
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => ShowRoyalty(180)}
                    className={royalty180daysselected ? "active" : ""}
                  >
                    180日
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => ShowRoyalty(365)}
                    className={royalty365daysselected ? "active" : ""}
                  >
                    365日
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => ShowRoyalty(0)}
                    className={royaltyalldaysselected ? "active" : ""}
                  >
                    全期間
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div
            className={
              holdingstabselected ? "chart-holdings active" : "chart-holdings"
            }
          >
            <div className="title">
              保有OIKOS総額
              <a onClick={() => ShowHoldingsDialog()} className="tips"></a>
            </div>
            <div className="holdings">
              ¥
              <CountUp
                end={ShowHoldingsTotalPrice(getholdingsgraph_data)}
                decimal="."
                decimals={
                  ShowHoldingsTotalPrice(getholdingsgraph_data) % 1 !== 0
                    ? 2
                    : 0
                }
              />
            </div>
            <div className="changerate">{ShowStrHoldingsChangeRate()}</div>

            <div class="collection-holdings-chart-box">
              <Line
                width={GetWidthForGraph()}
                height={GetHeightForGraph()}
                options={chartjs_options_for_Holdings}
                data={CreateHoldingsData(getholdingsgraph_data)}
              />
            </div>

            <div className="holdings-period-selection-area">
              <ul>
                <li>
                  <a
                    onClick={() => ShowHoldings(90)}
                    className={holdings90daysselected ? "active" : ""}
                  >
                    90日
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => ShowHoldings(180)}
                    className={holdings180daysselected ? "active" : ""}
                  >
                    180日
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => ShowHoldings(365)}
                    className={holdings365daysselected ? "active" : ""}
                  >
                    365日
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => ShowHoldings(0)}
                    className={holdingsalldaysselected ? "active" : ""}
                  >
                    全期間
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="custody-area">
          <div className="title">
            預かり金総額
            <a onClick={() => ShowCustodyDialog()} className="tips"></a>
          </div>
          <div className="inner">
            <dl>
              <dt>
                ¥
                {(
                  Math.floor(
                    getcustody_data.totalAndPayableAmount.totalAmount * 100
                  ) / 100
                ).toLocaleString(undefined, {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}
              </dt>
              <dd>
                (払出可能金額 : ¥
                {getcustody_data.totalAndPayableAmount.payableAmount.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  }
                )}
                )
              </dd>
            </dl>
            <nav>
              <a onClick={() => GoToPayout()}>内訳・払出</a>
            </nav>
          </div>
        </div>

        <div className="owner-limited-content">
          <h2>オーナー限定コンテンツ</h2>
          <div className="inner">
            <div className="slide">
              {getownerlimitedcontent_data.oikosClubArticles.length > 0 &&
                getownerlimitedcontent_data.oikosClubArticles.map((log, i) => {
                  return (
                    <>
                      <div
                        className={
                          i + 1 > ownerlimitedcontent_showingcount
                            ? "hide"
                            : "slide-item"
                        }
                      >
                        <a
                          href={
                            process.env.REACT_APP_OIKOS_URL +
                            "blogs/club_oikos/" +
                            log.handle
                          }
                        >
                          <dl>
                            <dt>{log.publishedAt}</dt>
                            <dd>{log.tags}</dd>
                          </dl>
                          <div className="title">{log.title}</div>
                        </a>
                      </div>
                    </>
                  );
                })}
            </div>

            <nav>
              <a
                className={
                  getownerlimitedcontent_data.oikosClubArticles.length >
                  ownerlimitedcontent_showingcount
                    ? "more"
                    : "more disabled"
                }
                onClick={() => ShowMoreOwnerLimitedContent()}
              >
                SHOW MORE
              </a>
            </nav>
          </div>
        </div>

        <div className="collection-list">
          <h2>保有OIKOS一覧</h2>
          {false && (
            <div className="tab">
              <button data-type="owned" onClick={onTabClick}>
                保有中
              </button>
              <button data-type="sold" onClick={onTabClick}>
                売却済み
              </button>
            </div>
          )}
          {false && (
            <div className="menu">
              <button>
                <img src="img/icon_filter.svg" alt="" />
              </button>
            </div>
          )}
          <ul
            data-type="owned"
            className={currentTab === "owned" ? "active" : ""}
          >
            {data.ownedItems.length > 0 &&
              data.ownedItems.map((log, i) => {
                return (
                  <li
                    key={log.id}
                    className={i + 1 > ownedItems_showingcount ? "hide" : ""}
                  >
                    <Link to={"/" + log.id}>
                      <div className="jacket">
                        <img src={log.right.thumbnail.url} alt="" />
                      </div>
                      <div className="info">
                        <div className="title">{log.right.album.title}</div>
                        <div className="artist">{log.right.artist.name}</div>

                        <div className="data">
                          <dl>
                            <dt>収益分配率</dt>
                            <dd>{log.right.totalPercentage} % </dd>
                          </dl>

                          <dl>
                            <dt>保有額</dt>
                            <dd>
                              ¥
                              {log.totalPrice.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                              })}
                            </dd>
                          </dl>
                        </div>

                        <ul>
                          <li>
                            <span className="thumb">
                              <img
                                src="img/square_silver.png"
                                alt="Silver Icon"
                              />
                            </span>
                            <strong>{log.right.owned.silver || "0"}</strong>
                          </li>
                          <li>
                            <span className="thumb">
                              <img src="img/square_gold.png" alt="Gold Icon" />
                            </span>
                            <strong>{log.right.owned.gold || "0"}</strong>
                          </li>
                          <li>
                            <span className="thumb">
                              <img
                                src="img/square_platinum.png"
                                alt="Platinum Icon"
                              />
                            </span>
                            <strong>{log.right.owned.platinum || "0"}</strong>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </li>
                );
              })}

            {data.ownedItems.length === 0 && (
              <div className="inner not-found">
                <p>
                  購入済みの商品はありません。
                  <br />
                  OIKOSを購入して、お気に入りのアーティストを応援しよう。
                </p>
                <div className="function">
                  <a href={process.env.REACT_APP_OIKOS_URL + "collections/all"}>
                    商品を購入する
                  </a>
                </div>
              </div>
            )}
          </ul>

          <nav>
            <a
              className={
                data.ownedItems.length > ownedItems_showingcount
                  ? "more"
                  : "more disabled"
              }
              onClick={() => ShowMoreOwnedItems()}
            >
              SHOW MORE
            </a>
          </nav>

          {/*
          <ul
            data-type="sold"
            className={currentTab === "sold" ? "active" : ""}
          >
            {data.soldItems.length > 0 &&
              data.soldItems.map((log) => {
                return (
                  <li key={log.id}>
                    <Link to={"/" + log.id}>
                      <div className="jacket">
                        <img src={log.right.thumbnail.url} alt="" />
                      </div>
                      <div className="info">
                        <div className="title">{log.right.song.title}</div>
                        <div className="artist">{log.right.artist.name}</div>

                        <dl>
                          <dt>収益分配率</dt>
                          <dd>{log.right.totalPercentage} % </dd>
                        </dl>

                        <ul>
                          <li>
                            <span className="thumb">
                              <img
                                src="img/square_silver.png"
                                alt="Silver Icon"
                              />
                            </span>
                            <strong>{log.right.owned.silver}</strong>
                          </li>
                          <li>
                            <span className="thumb">
                              <img src="img/square_gold.png" alt="Gold Icon" />
                            </span>
                            <strong>{log.right.owned.gold}</strong>
                          </li>
                          <li>
                            <span className="thumb">
                              <img
                                src="img/square_platinum.png"
                                alt="Platinum Icon"
                              />
                            </span>
                            <strong>{log.right.owned.platinum || "0"}</strong>
                          </li>
                        </ul>
                      </div>
                    </Link>
                  </li>
                );
              })}
            
            {data.soldItems.length === 0 && (
              <div className="inner not-found">
                <p>
                  購入済みの商品はありません。
                  <br />
                  OIKOSを購入して、お気に入りのアーティストを応援しよう。
                </p>
                <div className="function">
                  <a href={process.env.REACT_APP_OIKOS_URL + "collections/all"}>
                    商品を購入する
                  </a>
                </div>
              </div>
            )}
            
          </ul>
          */}
        </div>

        <p className="reflection-notes">
          NFTの発行及び所有OIKOSの反映に時間がかかる場合があります。
        </p>
      </div>
    </main>
  );
};
export default Collection;
