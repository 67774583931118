import { useQuery } from "@shopify/react-graphql";
import gql from "graphql-tag";
import {
  Link,
  useParams,
  useNavigate,
  useOutletContext,
} from "react-router-dom";
import Slider from "react-slick";
import React, { useState, useRef, useEffect } from "react";
import {
  Chart,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import CountUp from "react-countup";

let CollectionDetails_isAllLoaded = false;

const CollectionDetails = () => {
  let navigate = useNavigate();

  const { id } = useParams();

  Chart.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
  );

  const [
    ShowStreamDialog,
    ShowCollectionDetailStreamDialog,
    ShowRoyaltyDialog,
    ShowSongRoyaltyDialog,
    ShowHoldingsDialog,
    ShowCustodyDialog,
    ShowPayoutDialog,
  ] = useOutletContext();

  const chartjs_options_for_Streaming = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 1,
    layout: {
      padding: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      labels: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: function (handler) {
          externalTooltipHandlerForStreaming(handler);
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          color: "#ffffff",
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          color: "#000000",
          align: "center",
          minRotation: 0,
          maxRotation: 0,
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value == 0) {
              return "rgba(255,255,255,0.9)";
            } else {
              return "#333333";
            }
          },
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          count: 3,
          color: "#000000",
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
          callback: function (val, index) {
            if (index != 0) {
              let x = ("" + val).length;
              let p = Math.pow;
              let d = p(10, 1);
              x -= x % 3;
              let rt = Math.round((val * d) / p(10, x)) / d + " KMGTPE"[x / 3];
              if (rt == "0K") {
                rt = "0";
              }
              return rt;
            }
          },
        },
      },
    },
  };

  const chartjs_options_for_Royalty = {
    responsive: true,
    maintainAspectRatio: false,
    devicePixelRatio: 1,
    layout: {
      padding: 0,
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    interaction: {
      mode: "index",
      intersect: false,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
      labels: {
        display: false,
      },
      tooltip: {
        enabled: false,
        position: "nearest",
        external: function (handler) {
          externalTooltipHandlerForRoyalty(handler);
        },
      },
    },

    scales: {
      x: {
        grid: {
          display: false,
          color: "#ffffff",
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          color: "#000000",
          align: "center",
          minRotation: 0,
          maxRotation: 0,
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        beginAtZero: true,
        grid: {
          display: true,
          color: function (context) {
            if (context.tick.value == 0) {
              return "rgba(255,255,255,0.9)";
            } else {
              return "#333333";
            }
          },
        },
        ticks: {
          display: true,
          padding: 0,
          backdropPadding: 0,
          count: 3,
          color: "#000000",
          font: {
            family: "Poppins",
            weight: "300",
            size: 11,
          },
          callback: function (val, index) {
            if (index != 0) {
              let x = ("" + val).length;
              let p = Math.pow;
              let d = p(10, 1);
              x -= x % 3;
              let rt = Math.round((val * d) / p(10, x)) / d + " KMGTPE"[x / 3];
              if (rt == "0K") {
                rt = "0";
              }
              return rt;
            }
          },
        },
      },
    },
  };

  const OWNEDITEMS_SHOWCOUNT = 10;
  const OWNERLIMITEDCONTENT_SHOWCOUNT = 3;

  const [ownedItems_showingcount, setownedItems_showingcount] = useState(10);

  const [albumsongselectionFlg, setalbumsongselectionFlg] = useState(false);
  const [stralbumsong, setstralbumsong] = useState("全収録曲");

  const [cur_album_id, setcur_album_id] = useState("");
  const [cur_song_id, setcur_song_id] = useState("");

  const [strbigalbumsong, setstrbigalbumsong] = useState("");

  const [
    ownerlimitedcontent_showingcount,
    setownerlimitedcontent_showingcount,
  ] = useState(3);

  const [playcount_period, setplaycount_period] = useState(0);
  const [royalty_period, setroyalty_period] = useState(0);

  const [playcount7daysselected, setplaycount7daysselected] = useState(false);
  const [playcount28daysselected, setplaycount28daysselected] = useState(false);
  const [playcount90daysselected, setplaycount90daysselected] = useState(false);
  const [playcount180daysselected, setplaycount180daysselected] =
    useState(false);
  const [playcount365daysselected, setplaycount365daysselected] =
    useState(false);
  const [playcountalldaysselected, setplaycountalldaysselected] =
    useState(false);

  const [royalty90daysselected, setroyalty90daysselected] = useState(false);
  const [royalty180daysselected, setroyalty180daysselected] = useState(false);
  const [royalty365daysselected, setroyalty365daysselected] = useState(false);
  const [royaltyalldaysselected, setroyaltyalldaysselected] = useState(false);

  const GET_ITEM = gql`
    query GetItemById($id: ID!, $sort: String!) {
      item(id: $id) {
        id
        createdAt
        daysOwned
        sold
        right {
          productVariant {
            product {
              handle
            }
          }
          id
          createdAt
          isOnSale
          rank
          owned {
            silver
            gold
            platinum
            custom
          }
          song {
            title
          }
          album {
            title
          }
          artist {
            name
          }
          token {
            serialCode
          }
          image {
            url
          }
          tradingLogs {
            price
            createdAt
            priceDifference
          }
          relatedRights(order: $sort) {
            id
            rank
            percentage
            boughtAt
            token {
              serialCode
            }
            productVariant {
              shopifyVariantTitle
              price
              rank
              inventory
            }
          }
        }
      }
    }
  `;

  const GET_OWNEDALBUMS = gql`
    query OwnedAlbums($tradingLogId: Int) {
      ownedAlbums(tradingLogId: $tradingLogId) {
        id
        title
        songs {
          id
          title
        }
      }
    }
  `;

  const GET_SONGANALYSIS = gql`
    query SongAnalysis(
      $tradingLogId: Int!
      $period: Int
      $songId: Int
      $albumId: Int
    ) {
      songAnalysis(
        tradingLogId: $tradingLogId
        period: $period
        songId: $songId
        albumId: $albumId
      ) {
        streamPlayCount {
          date
          playCount
        }
        royalty {
          month
          amount
        }
        articles {
          title
          handle
          publishedAt
          tags
        }
        totalPrice
        revenueSharePercent
        streamPlayCountChangePercent
      }
    }
  `;

  const GET_SONGANALYSIS_FOR_ROYALTY = gql`
    query SongAnalysis(
      $tradingLogId: Int!
      $period: Int
      $songId: Int
      $albumId: Int
    ) {
      songAnalysis(
        tradingLogId: $tradingLogId
        period: $period
        songId: $songId
        albumId: $albumId
      ) {
        streamPlayCount {
          date
          playCount
        }
        royalty {
          month
          amount
        }
        articles {
          title
          handle
          publishedAt
          tags
        }
        totalPrice
        revenueSharePercent
        streamPlayCountChangePercent
      }
    }
  `;

  const sliderSettings = {
    className: "slide",
    slidesToShow: 1,
    speed: 1000,
    fade: false,
    infinite: false,
    draggable: true,
    swipe: true,
    arrows: false,
    dots: true,
  };
  const modalSliderSettings = {
    className: "slide",
    slidesToShow: 1,
    speed: 1000,
    fade: true,
    infinite: false,
    draggable: false,
    arrows: false,
    dots: true,
  };
  const sliderRef = useRef(null);
  // const [itemData, setItemData] = useState({});
  const [modalShown, setModalShown] = useState(false);
  const [viewIsWide, setViewIsWide] = useState(false);
  const [motionCount, setMotionCount] = useState(1);
  const [sortBy, setSortBy] = useState("rank");
  const handleSort = (event) => {
    setSortBy(event.target.value);
    refetch({
      variables: { id: id, sort: sortBy },
    });
  };
  const align = () => {
    var w = window.innerWidth;
    var h = window.innerHeight;

    if (w > h) {
      if (!viewIsWide) setViewIsWide(true);
    } else {
      if (viewIsWide) setViewIsWide(false);
    }
  };
  const showModal = (index) => {
    sliderRef.current.slickGoTo(index);
    setModalShown(true);
    align();
    window.addEventListener("resize", align);
  };
  const hideModal = () => {
    setModalShown(false);
  };

  function ShowMoreOwnedItems() {
    if (data.item.right.relatedRights.length > ownedItems_showingcount) {
      setownedItems_showingcount(
        ownedItems_showingcount + OWNEDITEMS_SHOWCOUNT
      );
    }
  }

  function ShowAlbumSongSelection() {
    document.body.classList.add("lock");
    document.body.classList.add("dark");
    document.getElementById("html-tag").classList.add("lock");
    document.getElementById("collection-detail").classList.add("dark");
    setalbumsongselectionFlg(true);
  }

  function HideAlbumSongSelection() {
    document.body.classList.remove("lock");
    document.body.classList.remove("dark");
    document.getElementById("html-tag").classList.remove("lock");
    document.getElementById("collection-detail").classList.remove("dark");
    setalbumsongselectionFlg(false);
  }

  function SelectAllAlbumSong() {
    setcur_album_id("");
    setcur_song_id("");
    setstralbumsong("全収録曲");
    setstrbigalbumsong(data.item.right.album.title);
    HideAlbumSongSelection();
    getsonganalysis_refetch({
      tradingLogId: parseInt(id),
      period: playcount_period,
      songId: ConvertSongId(""),
      albumId: ConvertAlbumId(""),
    });
    getsonganalysis_for_royalty_refetch({
      tradingLogId: parseInt(id),
      period: royalty_period,
      songId: ConvertSongId(""),
      albumId: ConvertAlbumId(""),
    });
  }

  function SelectAlbum(album_id, title) {
    setcur_album_id(album_id);
    setcur_song_id("");
    setstralbumsong(title);
    setstrbigalbumsong(title);
    HideAlbumSongSelection();
    getsonganalysis_refetch({
      tradingLogId: parseInt(id),
      period: playcount_period,
      songId: ConvertSongId(""),
      albumId: ConvertAlbumId(album_id),
    });
    getsonganalysis_for_royalty_refetch({
      tradingLogId: parseInt(id),
      period: royalty_period,
      songId: ConvertSongId(""),
      albumId: ConvertAlbumId(album_id),
    });
  }

  function SelectSong(album_id, song_id, title) {
    setcur_album_id(album_id);
    setcur_song_id(song_id);
    setstralbumsong(title);
    setstrbigalbumsong(title);
    HideAlbumSongSelection();
    getsonganalysis_refetch({
      tradingLogId: parseInt(id),
      period: playcount_period,
      songId: ConvertSongId(song_id),
      albumId: ConvertAlbumId(album_id),
    });
    getsonganalysis_for_royalty_refetch({
      tradingLogId: parseInt(id),
      period: royalty_period,
      songId: ConvertSongId(song_id),
      albumId: ConvertAlbumId(album_id),
    });
  }

  function ConvertSongId(songid) {
    if (songid == "") {
      return null;
    } else {
      return parseInt(songid);
    }
  }

  function ConvertAlbumId(albumid) {
    if (albumid == "") {
      return null;
    } else {
      return parseInt(albumid);
    }
  }

  function ShowMoreOwnerLimitedContent() {
    if (
      getsonganalysis_data.songAnalysis.articles.length >
      ownerlimitedcontent_showingcount
    ) {
      setownerlimitedcontent_showingcount(
        ownerlimitedcontent_showingcount + OWNERLIMITEDCONTENT_SHOWCOUNT
      );
    }
  }

  function GoToPayout() {
    CollectionDetails_isAllLoaded = false;
    navigate("/payout");
  }

  function GetWidthForGraph() {
    if (window.innerWidth >= 1024) {
      return 886;
    } else {
      return 330;
    }
  }

  function GetHeightForGraph() {
    if (window.innerWidth >= 1024) {
      return 300;
    } else {
      return 280;
    }
  }

  function externalTooltipHandlerForStreaming(context) {
    var chart = context.chart;
    var tooltip = context.tooltip;

    var el = getTooltipForStreaming();

    if (tooltip.opacity === 0) {
      el.style.opacity = 0;
      return;
    }

    var date = tooltip.title[0];
    var ary = [];
    date.split("/").map((val) => {
      ary.push(Number(val));
    });

    var title = ary.join("<i>/</i>");
    var value = tooltip.body[0].lines[0];

    document.querySelector(".collection-streaming-chart-tip div").innerHTML =
      "<span>" + title + "</span><br><span>" + value + "</span>";

    var { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    el.style.opacity = 1;
    el.style.left = positionX + tooltip.caretX + "px";
    document.querySelector(
      ".collection-streaming-chart-tip-line"
    ).style.height = tooltip.caretY + 20 + "px";
  }

  function getTooltipForStreaming() {
    var tooltip = document.querySelector(".collection-streaming-chart-tip");
    if (!tooltip) {
      tooltip = document.createElement("div");
      tooltip.className = "collection-streaming-chart-tip";

      var label = document.createElement("div");
      var line = document.createElement("span");
      line.className = "collection-streaming-chart-tip-line";
      tooltip.appendChild(label);
      tooltip.appendChild(line);
      document
        .querySelector(".detail-streaming-chart-box")
        .appendChild(tooltip);
    }
    return tooltip;
  }

  function externalTooltipHandlerForRoyalty(context) {
    var chart = context.chart;
    var tooltip = context.tooltip;

    var el = getTooltipForRoyalty();

    if (tooltip.opacity === 0) {
      el.style.opacity = 0;
      return;
    }

    var date = tooltip.title[0];
    var ary = [];
    date.split("/").map((val) => {
      ary.push(Number(val));
    });

    var title = ary.join("<i>/</i>");
    var value = tooltip.body[0].lines[0];

    document.querySelector(".collection-royalty-chart-tip div").innerHTML =
      "<span>" + title + "</span><br><span>" + value + "</span>";

    var { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;
    el.style.opacity = 1;
    el.style.left = positionX + tooltip.caretX + "px";
    document.querySelector(".collection-royalty-chart-tip-line").style.height =
      tooltip.caretY + 20 + "px";
  }

  function getTooltipForRoyalty() {
    var tooltip = document.querySelector(".collection-royalty-chart-tip");
    if (!tooltip) {
      tooltip = document.createElement("div");
      tooltip.className = "collection-royalty-chart-tip";

      var label = document.createElement("div");
      var line = document.createElement("span");
      line.className = "collection-royalty-chart-tip-line";
      tooltip.appendChild(label);
      tooltip.appendChild(line);
      document.querySelector(".detail-royalty-chart-box").appendChild(tooltip);
    }
    return tooltip;
  }

  function CreateStreamingData(data) {
    let labels = [];
    data.map((d) => {
      if (window.innerWidth >= 1024) {
        labels.push(d.date.replaceAll("-", "/"));
      } else {
        let date = d.date.split("-");
        labels.push(parseInt(date[1]) + "/" + parseInt(date[2]));
      }
    });
    let dt = [];
    data.map((d) => {
      dt.push(d.playCount);
    });

    return {
      labels,
      datasets: [
        {
          label: "",
          tension: 0,
          data: dt,
          borderColor: "rgb(89, 50, 255)",
          backgroundColor: "rgba(89, 50, 255, 0.2)",
          fill: true,
        },
      ],
    };
  }

  function CreateRoyaltyData(data) {
    let labels = [];
    data.map((d) => {
      if (window.innerWidth >= 1024) {
        labels.push(d.month.replaceAll("-", "/"));
      } else {
        let date = d.month.split("-");
        labels.push(parseInt(date[1]) + "/" + parseInt(date[2]));
      }
    });
    let dt = [];
    data.map((d) => {
      dt.push(d.amount);
    });

    return {
      labels,
      datasets: [
        {
          label: "",
          tension: 0,
          data: dt,
          borderColor: "rgb(89, 50, 255)",
          backgroundColor: "rgba(89, 50, 255, 0.2)",
          fill: true,
        },
      ],
    };
  }

  function ShowPlayCount(period) {
    setplaycount_period(period);

    if (period == 7) {
      setplaycount7daysselected(true);
      setplaycount28daysselected(false);
      setplaycount90daysselected(false);
      setplaycount180daysselected(false);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(false);
    } else if (period == 28) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(true);
      setplaycount90daysselected(false);
      setplaycount180daysselected(false);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(false);
    } else if (period == 90) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(false);
      setplaycount90daysselected(true);
      setplaycount180daysselected(false);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(false);
    } else if (period == 180) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(false);
      setplaycount90daysselected(false);
      setplaycount180daysselected(true);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(false);
    } else if (period == 365) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(false);
      setplaycount90daysselected(false);
      setplaycount180daysselected(false);
      setplaycount365daysselected(true);
      setplaycountalldaysselected(false);
    } else if (period == 0) {
      setplaycount7daysselected(false);
      setplaycount28daysselected(false);
      setplaycount90daysselected(false);
      setplaycount180daysselected(false);
      setplaycount365daysselected(false);
      setplaycountalldaysselected(true);
    }

    let tmp_period;

    if (period == 0) {
      tmp_period = period;
    } else {
      tmp_period = period - 1;
    }

    getsonganalysis_refetch({
      tradingLogId: parseInt(id),
      period: tmp_period,
      songId: ConvertSongId(cur_song_id),
      albumId: ConvertAlbumId(cur_album_id),
    });
  }

  function ShowRoyalty(period) {
    setroyalty_period(period);

    if (period == 90) {
      setroyalty90daysselected(true);
      setroyalty180daysselected(false);
      setroyalty365daysselected(false);
      setroyaltyalldaysselected(false);
    } else if (period == 180) {
      setroyalty90daysselected(false);
      setroyalty180daysselected(true);
      setroyalty365daysselected(false);
      setroyaltyalldaysselected(false);
    } else if (period == 365) {
      setroyalty90daysselected(false);
      setroyalty180daysselected(false);
      setroyalty365daysselected(true);
      setroyaltyalldaysselected(false);
    } else if (period == 0) {
      setroyalty90daysselected(false);
      setroyalty180daysselected(false);
      setroyalty365daysselected(false);
      setroyaltyalldaysselected(true);
    }

    let tmp_period;

    if (period == 0) {
      tmp_period = period;
    } else {
      tmp_period = period - 1;
    }

    getsonganalysis_for_royalty_refetch({
      tradingLogId: parseInt(id),
      period: tmp_period,
      songId: ConvertSongId(cur_song_id),
      albumId: ConvertAlbumId(cur_album_id),
    });
  }

  function SumStreamPlayCount(playcount) {
    return playcount.reduce((sum, element) => sum + element.playCount, 0);
  }

  function SumRoyaltyAmount(royalty) {
    return royalty.reduce((sum, element) => sum + element.amount, 0);
  }

  function ConvertTerm(period) {
    if (period == 0) {
      return "全期間";
    } else {
      return period + "日";
    }
  }

  function ConvertPlayCountChangeRate(changerate) {
    return changerate;
  }

  function ConvertRoyaltyChangeRate(data) {
    if (data.songAnalysis.royalty.length > 0) {
      let chageRate =
        ((data.songAnalysis.royalty[data.songAnalysis.royalty.length - 1]
          .amount -
          data.songAnalysis.royalty[0].amount) /
          data.songAnalysis.royalty[0].amount) *
        100;
      return Math.ceil(chageRate);
    } else {
      return 0;
    }
  }

  function CalcRoyaltyChangeRate(data) {
    if (data.songAnalysis.royalty.length > 0) {
      let chageRate =
        ((data.songAnalysis.royalty[data.songAnalysis.royalty.length - 1]
          .amount -
          data.songAnalysis.royalty[0].amount) /
          data.songAnalysis.royalty[0].amount) *
        100;
      return Math.ceil(chageRate);
    } else {
      return 0;
    }
  }

  function ShowBigAlbumSong() {
    if (strbigalbumsong == "") {
      return data.item.right.album.title;
    } else {
      return strbigalbumsong;
    }
  }

  function ShowUpDown(rate) {
    if (rate < 0) {
      return (
        <>
          <font class="down">▼</font>
        </>
      );
    } else {
      return (
        <>
          <font class="up">▲</font>
        </>
      );
    }
  }

  function ShowStrPlayCountChangeRate() {
    if (
      getsonganalysis_data.songAnalysis.streamPlayCountChangePercent != null
    ) {
      return (
        <>
          {ShowUpDown(
            getsonganalysis_data.songAnalysis.streamPlayCountChangePercent
          )}
          過去{ConvertTerm(playcount_period)}で
          {ConvertPlayCountChangeRate(
            getsonganalysis_data.songAnalysis.streamPlayCountChangePercent
          )}
          %変化しました
        </>
      );
    } else {
      return <>※まだデータがありません</>;
    }
  }

  function ShowStrRoyaltyChangeRate() {
    if (getsonganalysis_for_royalty_data.songAnalysis.royalty.length > 0) {
      return (
        <>
          {ShowUpDown(CalcRoyaltyChangeRate(getsonganalysis_for_royalty_data))}
          過去{ConvertTerm(royalty_period)}で
          {ConvertRoyaltyChangeRate(getsonganalysis_for_royalty_data)}
          %変化しました
        </>
      );
    } else {
      return <>※まだデータがありません</>;
    }
  }

  useEffect(() => {
    const motionTimer = setInterval(function () {
      if (modalShown) {
        let nextCount = motionCount !== 75 ? motionCount + 1 : 1;
        setMotionCount(nextCount);
      }
    }, 66);

    return () => clearInterval(motionTimer);
  }, [modalShown, motionCount]);

  const { loading, error, data, refetch } = useQuery(GET_ITEM, {
    variables: { id: id, sort: sortBy },
  });
  const {
    loading: getownedalbums_loading,
    error: getownedalbums_error,
    data: getownedalbums_data,
    refetch: getownedalbums_refetch,
  } = useQuery(GET_OWNEDALBUMS, {
    variables: { tradingLogId: parseInt(id) },
  });
  const {
    loading: getsonganalysis_loading,
    error: getsonganalysis_error,
    data: getsonganalysis_data,
    refetch: getsonganalysis_refetch,
  } = useQuery(GET_SONGANALYSIS, {
    variables: {
      tradingLogId: parseInt(id),
      period: 89,
      songId: ConvertSongId(cur_song_id),
      albumId: ConvertAlbumId(cur_album_id),
    },
  });
  const {
    loading: getsonganalysis_for_royalty_loading,
    error: getsonganalysis_for_royalty_error,
    data: getsonganalysis_for_royalty_data,
    refetch: getsonganalysis_for_royalty_refetch,
  } = useQuery(GET_SONGANALYSIS_FOR_ROYALTY, {
    variables: {
      tradingLogId: parseInt(id),
      period: 89,
      songId: ConvertSongId(cur_song_id),
      albumId: ConvertAlbumId(cur_album_id),
    },
  });

  // useEffect(() => {
  //  if (data) setItemData(data);
  // }, [data]);

  useEffect(() => {
    ShowPlayCount(90);
    ShowRoyalty(90);
    refetch({ id: id, sort: sortBy });
  }, []);

  if (error) {
    return (
      <main className="collection content">
        <div className="inner not-found">
          <p>コレクションページを開くにはログインが必要です。</p>
          <div className="function">
            <a href={process.env.REACT_APP_OIKOS_URL + "account/login"}>
              LOGINへ
            </a>
          </div>
        </div>
      </main>
    );
  }
  if (
    CollectionDetails_isAllLoaded == false &&
    (loading ||
      getownedalbums_loading ||
      getsonganalysis_loading ||
      getsonganalysis_for_royalty_loading)
  ) {
    return (
      <main className="collection content">
        <div className="inner acquisition">Loading . . .</div>
      </main>
    );
  }

  CollectionDetails_isAllLoaded = true;

  return (
    <>
      <main id="collection-detail" className="detail">
        <div className="viewer">
          <Slider {...sliderSettings}>
            {/*
            <div className="item">
              <a>
                <span>
                  <img
                    src={`img/bar_silver${
                      itemData.item.right.owned.silver &&
                      itemData.item.right.owned.silver > 0
                        ? ""
                        : "_dark"
                    }.png`}
                    alt=""
                  />
                </span>
                <dl>
                  <dt>OIKOS Silver</dt>
                  <dd>{itemData.item.right.owned.silver || "0"}</dd>
                </dl>
              </a>
            </div>
            <div className="item">
              <a>
                <span>
                  <img
                    src={`img/bar_gold${
                      itemData.item.right.owned.gold &&
                      itemData.item.right.owned.gold > 0
                        ? ""
                        : "_dark"
                    }.png`}
                    alt=""
                  />
                </span>
                <dl>
                  <dt>OIKOS Gold</dt>
                  <dd>{itemData.item.right.owned.gold || "0"}</dd>
                </dl>
              </a>
            </div>
            <div className="item">
              <a>
                <span>
                  <img
                    src={`img/bar_platinum${
                      itemData.item.right.owned.platinum &&
                      itemData.item.right.owned.platinum > 0
                        ? ""
                        : "_dark"
                    }.png`}
                    alt=""
                  />
                </span>
                <dl>
                  <dt>OIKOS Platinum</dt>
                  <dd>{itemData.item.right.owned.platinum || "0"}</dd>
                </dl>
              </a>
            </div>
            */}
            <div className="item">
              <div className="oikos-mini">
                <ul>
                  <li>
                    <div className="bar">
                      <img src="img/bar_silver2.png" alt=""></img>
                    </div>
                    <div className="count">
                      {data.item.right.owned.silver || "0"}
                    </div>
                  </li>
                  <li>
                    <div className="bar">
                      <img src="img/bar_gold2.png" alt=""></img>
                    </div>
                    <div className="count">
                      {data.item.right.owned.gold || "0"}
                    </div>
                  </li>
                  <li>
                    <div className="bar">
                      <img src="img/bar_platinum2.png" alt=""></img>
                    </div>
                    <div className="count">
                      {data.item.right.owned.platinum || "0"}
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="item">
              <div className="jacket">
                <img src={data.item.right.image.url} alt=""></img>
              </div>
            </div>
          </Slider>
          <div className="bg">
            <img src={data.item.right.image.url} alt="" />
          </div>
        </div>

        <div
          id="modal"
          className={`${modalShown ? "modal-view" : ""} ${
            viewIsWide ? "wide" : "tall"
          }`}
        >
          <Slider ref={sliderRef} {...modalSliderSettings}>
            {data.item.right.relatedRights.map((right) => (
              <div className="item" style={{ height: "100%" }} key={right.id}>
                <div className="view" data-loaded="1">
                  <div className="bar" data-type={right.rank}>
                    <span className="preview">
                      <img
                        src={
                          ["silver", "gold", "platinum"].includes(right.rank)
                            ? `img/bar_${right.rank}.png?2`
                            : ""
                        }
                        alt=""
                      />
                    </span>
                  </div>
                  <div className="motion">
                    {Array.from({ length: 75 }, (_, i) => i + 1).map((i) => (
                      <img
                        key={i}
                        src={
                          ["silver", "gold", "platinum"].includes(right.rank)
                            ? `motion/${right.rank}/${i}.png`
                            : ""
                        }
                        className={
                          motionCount === i ? "active loaded" : "loaded"
                        }
                        alt={`Motion ${right.rank} ${i}`}
                      />
                    ))}
                  </div>
                </div>
                <div className="text">
                  <div className="data">
                    <span>
                      OIKOS
                      {" " +
                        right.productVariant.shopifyVariantTitle
                          .charAt(0)
                          .toUpperCase() +
                        right.productVariant.shopifyVariantTitle.slice(1)}
                    </span>
                    <span>No. {right.token.serialCode.padStart(4, "0")}</span>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
          <div className="info">
            <div className="title">{data.item.right.song.title}</div>
            <div className="artist">{data.item.right.artist.name}</div>
          </div>
          <div className="bg">
            <img src={data.item.right.image.url} alt="" />
          </div>
          <button id="modal-close" onClick={hideModal} onTouchEnd={hideModal}>
            <img src="img/icon_close.svg" alt="Close" />
          </button>
        </div>
        <div
          id="modal-overlay"
          onClick={hideModal}
          onTouchEnd={hideModal}
          className={modalShown ? "modal-view" : ""}
        ></div>

        <div className="inner">
          <div className="album-title">
            <div className="title">{ShowBigAlbumSong()}</div>
            <div className="artist">{data.item.right.artist.name}</div>
          </div>

          <div className="album-song-pulldown">
            <a onClick={ShowAlbumSongSelection}>
              <span>{stralbumsong}</span>
            </a>
          </div>

          {/*
          <div className="function">
            <a
              href={
                process.env.REACT_APP_OIKOS_URL +
                "products/" +
                itemData.item.right.productVariant.product.handle
              }
            >
              買い足す
            </a>
          </div>
          */}

          {/*
            <div className="pulldown-menu menu">
              <div className="pulldown filter">
                <button>
                  <img src="img/icon_sort.svg" alt="Sort icon" />
                </button>
                <select onChange={handleSort}>
                  <option value="rank">ランク順</option>
                  <option value="bought-asc">購入日が古い順</option>
                  <option value="bought-desc">購入日が新しい順</option>
                  <option value="price-desc">価格が高い順</option>
                  <option value="price-asc">価格が低い順</option>
                </select>
              </div>
            </div>
            */}

          <div class="detail-streaming-chart-group">
            <div class="chart">
              <h2>
                ストリーミング再生数
                <a
                  onClick={() => ShowCollectionDetailStreamDialog()}
                  className="tips"
                ></a>
              </h2>
              <dl>
                <dt>
                  <CountUp
                    end={SumStreamPlayCount(
                      getsonganalysis_data.songAnalysis.streamPlayCount
                    )}
                    decimal=","
                  />
                </dt>
                <dd class="show">{ShowStrPlayCountChangeRate()}</dd>
              </dl>

              <div class="detail-streaming-chart-box">
                <Line
                  width={GetWidthForGraph()}
                  height={GetHeightForGraph()}
                  options={chartjs_options_for_Streaming}
                  data={CreateStreamingData(
                    getsonganalysis_data.songAnalysis.streamPlayCount
                  )}
                />
              </div>

              <div className="chart-menu">
                <ul>
                  <li>
                    <a
                      onClick={() => ShowPlayCount(7)}
                      className={playcount7daysselected ? "active" : ""}
                    >
                      7日
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => ShowPlayCount(28)}
                      className={playcount28daysselected ? "active" : ""}
                    >
                      28日
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => ShowPlayCount(90)}
                      className={playcount90daysselected ? "active" : ""}
                    >
                      90日
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => ShowPlayCount(180)}
                      className={playcount180daysselected ? "active" : ""}
                    >
                      180日
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => ShowPlayCount(365)}
                      className={playcount365daysselected ? "active" : ""}
                    >
                      365日
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => ShowPlayCount(0)}
                      className={playcountalldaysselected ? "active" : ""}
                    >
                      全期間
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div class="detail-royalty-chart-group">
            <div class="chart">
              <h2>
                ロイヤリティー実績
                <a onClick={() => ShowSongRoyaltyDialog()} className="tips"></a>
              </h2>
              <dl>
                <dt>
                  ¥
                  <CountUp
                    end={SumRoyaltyAmount(
                      getsonganalysis_for_royalty_data.songAnalysis.royalty
                    )}
                    decimal="."
                    decimals={(SumRoyaltyAmount(
                      getsonganalysis_for_royalty_data.songAnalysis.royalty
                    ) % 1 !== 0) ? 2 : 0}
                  />
                </dt>
                <dd class="show">{ShowStrRoyaltyChangeRate()}</dd>
              </dl>

              <div class="detail-royalty-chart-box">
                <Line
                  width={GetWidthForGraph()}
                  height={GetHeightForGraph()}
                  options={chartjs_options_for_Royalty}
                  data={CreateRoyaltyData(
                    getsonganalysis_for_royalty_data.songAnalysis.royalty
                  )}
                />
              </div>

              <div className="chart-menu">
                <ul>
                  <li>
                    <a
                      onClick={() => ShowRoyalty(90)}
                      className={royalty90daysselected ? "active" : ""}
                    >
                      90日
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => ShowRoyalty(180)}
                      className={royalty180daysselected ? "active" : ""}
                    >
                      180日
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => ShowRoyalty(365)}
                      className={royalty365daysselected ? "active" : ""}
                    >
                      365日
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => ShowRoyalty(0)}
                      className={royaltyalldaysselected ? "active" : ""}
                    >
                      全期間
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="oikos-amount">
            <h2>保有OIKOS情報</h2>
            <div class="inner">
              <dl>
                <dt>収益分配率</dt>
                <dd>
                  {getsonganalysis_data.songAnalysis.revenueSharePercent}％
                </dd>
                <dd></dd>
              </dl>
              <dl>
                <dt>保有OIKOS額</dt>
                <dd>
                  ¥
                  {getsonganalysis_data.songAnalysis.totalPrice.toLocaleString(
                    undefined,
                    {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }
                  )}
                </dd>
                <dd></dd>
              </dl>
            </div>
            <nav>
              <a onClick={() => GoToPayout()}>内訳・払出</a>
            </nav>
          </div>

          <div className="owner-limited-content">
            <h2>オーナー限定コンテンツ</h2>
            <div className="inner">
              <div className="slide">
                {getsonganalysis_data.songAnalysis.articles.length > 0 &&
                  getsonganalysis_data.songAnalysis.articles.map((log, i) => {
                    return (
                      <>
                        <div
                          className={
                            i + 1 > ownerlimitedcontent_showingcount
                              ? "hide"
                              : "slide-item"
                          }
                        >
                          <a
                            href={
                              process.env.REACT_APP_OIKOS_URL +
                              "blogs/club_oikos/" +
                              log.handle
                            }
                          >
                            <dl>
                              <dt>{log.publishedAt}</dt>
                              <dd>{log.tags}</dd>
                            </dl>
                            <div className="title">{log.title}</div>
                          </a>
                        </div>
                      </>
                    );
                  })}
              </div>

              <nav>
                <a
                  className={
                    getsonganalysis_data.songAnalysis.articles.length >
                    ownerlimitedcontent_showingcount
                      ? "more"
                      : "more disabled"
                  }
                  onClick={() => ShowMoreOwnerLimitedContent()}
                >
                  SHOW MORE
                </a>
              </nav>
            </div>
          </div>

          <div className="oikos-compact">
            <h2>保有OIKOS一覧</h2>
            <ul>
              {data.item.right.relatedRights.map((right, index) => (
                <li
                  key={right.id}
                  className={index + 1 > ownedItems_showingcount ? "hide" : ""}
                >
                  <Link onClick={() => showModal(index)}>
                    <div className="thumb">
                      <img
                        src={
                          ["silver", "gold", "platinum"].includes(right.rank)
                            ? `img/bar_${right.rank}2.png`
                            : ""
                        }
                        alt=""
                      />
                    </div>
                    <div className="info">
                      <div class="title">
                        OIKOS
                        {" " +
                          right.productVariant.shopifyVariantTitle
                            .charAt(0)
                            .toUpperCase() +
                          right.productVariant.shopifyVariantTitle.slice(1)}
                      </div>
                      <div class="data">
                        <dl>
                          <dt>収益分配率</dt>
                          <dd>{right.percentage}%</dd>
                        </dl>
                        <dl>
                          <dt>シリアルナンバー</dt>
                          <dd>No. {right.token.serialCode.padStart(4, "0")}</dd>
                        </dl>
                        <dl>
                          <dt>保有額</dt>
                          <dd>
                            ¥
                            {right.productVariant.price.toLocaleString(
                              undefined,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 2,
                              }
                            )}
                            -
                          </dd>
                        </dl>
                        <dl>
                          <dt>購入日</dt>
                          <dd>
                            {new Date(right.boughtAt)
                              .toLocaleString("ja-JP", {
                                dateStyle: "short",
                              })
                              .replace(/\//g, "-")}
                          </dd>
                        </dl>
                      </div>
                    </div>
                  </Link>
                </li>
              ))}
            </ul>

            <nav>
              <a
                className={
                  data.item.right.relatedRights.length > ownedItems_showingcount
                    ? "more"
                    : "more disabled"
                }
                onClick={() => ShowMoreOwnedItems()}
              >
                SHOW MORE
              </a>
            </nav>
          </div>
        </div>
      </main>

      <div
        className={
          albumsongselectionFlg
            ? "album-song-selection-area active"
            : "album-song-selection-area"
        }
      >
        <div className="inner">
          <ul>
            <li>
              <a
                onClick={() => SelectAllAlbumSong()}
                className={
                  cur_album_id == "" && cur_song_id == "" ? "current" : ""
                }
              >
                全収録曲
              </a>
            </li>
            {getownedalbums_data.ownedAlbums.length > 0 &&
              getownedalbums_data.ownedAlbums.map((album, i) => (
                <>
                  <li>
                    <a
                      onClick={() => SelectAlbum(album.id, album.title)}
                      className={
                        cur_album_id == album.id && cur_song_id == ""
                          ? "current"
                          : ""
                      }
                    >
                      {album.title}
                    </a>
                    {album.songs.length > 0 && (
                      <ul>
                        {album.songs.map((song, j) => {
                          return (
                            <>
                              <li>
                                <a
                                  onClick={() =>
                                    SelectSong(album.id, song.id, song.title)
                                  }
                                  className={
                                    cur_album_id == album.id &&
                                    cur_song_id == song.id
                                      ? "current"
                                      : ""
                                  }
                                >
                                  {song.title}
                                </a>
                              </li>
                            </>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                </>
              ))}
          </ul>
        </div>
        <button
          type="album-song-selection-area-button"
          onClick={HideAlbumSongSelection}
        ></button>
      </div>

      <div id="selector-overlay"></div>
    </>
  );
};
export default CollectionDetails;
